import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { ModalService } from '@app/custom/features/modal';
import { ALGOLIA_SEARCH_NORMALIZER } from '@app/custom/features/rrs-search/adapter/rrs-search.normalizer';
import {
  RrsSearchConfig,
  RrsAlgolia,
} from '@app/custom/features/rrs-search/models/rrs-search.model';
import { RrsAlgoliaService } from '@app/custom/features/rrs-search/services/algolia.service';
import { RrsShopLookIconComponent } from '@app/custom/features/rrs-shop-look/components/rrs-shop-look-icon/rrs-shop-look-icon/rrs-shop-look-icon.component';
import { RrsShopLookMiniComponent } from '@app/custom/features/rrs-shop-look/components/rrs-shop-look-mini/rrs-shop-look-mini.component';
import { ConverterService, ProductSearchPage } from '@spartacus/core';
import { Observable } from 'rxjs';

declare var require: any;

@Injectable({
  providedIn: 'root',
})
export class RrsShopLookService {
  private productCodes: string[] = [];
  private productCode: string = '';
  private shopLookTitle: string = '';
  private isSideBarActive: boolean = false;
  constructor(
    protected rrsAlgoliaService: RrsAlgoliaService,
    protected converterService: ConverterService,
    protected modalService: ModalService,
    protected inject: Injector,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  createShopLookElement(): void {
    if (isPlatformBrowser(this.platformId)) {
      const { createCustomElement } = require('@angular/elements');
      const myCustomElement = createCustomElement(RrsShopLookIconComponent, {
        injector: this.inject,
      });
      if (!customElements.get('rrs-shop-look-icon'))
        customElements.define('rrs-shop-look-icon', myCustomElement);
    }
  }

  get codes(): string[] {
    return this.productCodes;
  }
  set codes(codes: string[]) {
    this.productCodes = codes;
  }

  get title(): string {
    return this.shopLookTitle;
  }
  set title(title: string) {
    this.shopLookTitle = title;
  }
  get code(): string {
    return this.productCode;
  }
  set code(code: string) {
    this.productCode = code;
  }
  get shopLookOpen(): boolean {
    return this.isSideBarActive;
  }
  set shopLookOpen(shopLookOpen: boolean) {
    this.isSideBarActive = shopLookOpen;
  }
  getProducts(config: RrsSearchConfig): Observable<ProductSearchPage> {
    const result = this.rrsAlgoliaService.search<RrsAlgolia.Hit>('', config);
    return result.pipe(
      this.converterService.pipeable(ALGOLIA_SEARCH_NORMALIZER)
    );
  }
  getSkuStringforSearch(productCodes: string[]): string {
    let skuFilter: string = '[';
    productCodes.forEach((code, index) => {
      skuFilter += '"sku:' + code + '"';
      if (productCodes.length - 1 !== index) {
        skuFilter += ',';
      }
    });
    skuFilter += ']';
    return skuFilter;
  }

  openPDP(code?: string): void {
    code = code ? (this.code = code) : this.code;
    const modalRef = this.modalService.open(RrsShopLookMiniComponent, {
      windowClass: 'rrs-shop-look',
      animation: false,
      centered: false,
      backdrop: 'static',
      keyboard: false,
      beforeDismiss: () => {
        this.shopLookOpen = false;
        return true;
      },
    });
    modalRef.componentInstance.productCode = this.code;
    modalRef.componentInstance.productCodes = this.codes;
  }
  openPLP(): void {
    const modalRef = this.modalService.open(RrsShopLookMiniComponent, {
      windowClass: 'rrs-shop-look',
      animation: false,
      centered: false,
      beforeDismiss: () => {
        this.shopLookOpen = false;
        return true;
      },
    });
    modalRef.componentInstance.productCode = null;
    modalRef.componentInstance.productCodes = this.codes;
  }
}
