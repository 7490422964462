import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RrsBasePageMetaResolver } from '@app/custom/features/seo/resolvers/rrs-base-page-meta.resolver';
import { RrsContentPageMetaResolver } from '@app/custom/features/seo/resolvers/rrs-content-page-meta.resolver';
import { PageRobotsMeta, RoutingService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export const DEFAULT_APPEND_TITLE = ' | Rack Room Shoes';
export const DEFAULT_TITLE = 'Shoes, Boots and Sandals' + DEFAULT_APPEND_TITLE;
@Injectable({
  providedIn: 'root',
})
export class RrsPlpPageTitleResolver extends RrsContentPageMetaResolver {
  constructor(
    protected basePageMetaResolver: RrsBasePageMetaResolver,
    protected routingService: RoutingService,
    private titlecasePipe: TitleCasePipe,
    private router: Router
  ) {
    super(basePageMetaResolver);
    this.pageTemplate = 'ProductListingPageTemplate';
  }

  resolveTitle(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveTitle().pipe(
      switchMap((pageTitle) => {
        if (pageTitle === DEFAULT_TITLE) {
          return this.resolveSlug().pipe(
            map((slug) => {
              if (!slug) {
                return DEFAULT_TITLE;
              }
              return slug + ' | Rack Room Shoes';
            })
          );
        }
        return of(pageTitle);
      })
    );
  }

  resolveDescription(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveDescription().pipe(
      switchMap((pageDescription) => {
        if (!pageDescription) {
          return this.resolveSlug().pipe(
            map((slug) => {
              if (!slug) {
                slug = 'Shoes, Boots and Sandals';
              }
              return `Shop ${slug} at Rack Room Shoes. Choose from the top brands, buy online or pick up in store today.`;
            })
          );
        }
        return of(pageDescription);
      })
    );
  }
  resolveHeading(): Observable<string | undefined> {
    return this.basePageMetaResolver.resolveHeading().pipe(
      switchMap((heading) => {
        if (heading) {
          return of(heading);
        }
        return this.resolveTitle().pipe(
          map((title) => {
            return title?.replace(DEFAULT_APPEND_TITLE, '');
          })
        );
      })
    );
  }

  resolveRobots(): Observable<PageRobotsMeta[]> {
    return this.basePageMetaResolver.resolveRobots().pipe(
      map((robots) => {
        return this.router.url.includes('/search') || !robots.length
          ? [PageRobotsMeta.NOINDEX, PageRobotsMeta.NOFOLLOW]
          : robots;
      })
    );
  }

  private resolveSlug(): Observable<string | null> {
    return this.routingService.getParams().pipe(
      map((query) => {
        if (query?.query) {
          return this.convertEndPointToTitle(query?.query);
        }
        return null;
      })
    );
  }

  private convertEndPointToTitle(slug: string): string {
    const replaceStringObj: { [key: string]: string } = {
      '-': ' ',
      womens: "women's",
      mens: "men's",
      girls: "girls'",
      boys: "boys'",
      kids: "kids'",
      and: ' and ',
    };
    const regex = new RegExp(Object.keys(replaceStringObj).join('|'), 'gi');
    slug = slug.replace(regex, (matched) => {
      return replaceStringObj[matched];
    });
    const sandajsObj: { [key: string]: string } = {
      's and als': 'sandals',
      's and al': 'sandal',
    };
    const regexForSandals = new RegExp(Object.keys(sandajsObj).join('|'), 'gi');
    slug = slug.replace(regexForSandals, (matched) => {
      return sandajsObj[matched];
    });
    return this.titlecasePipe
      .transform(slug)
      .replace(/And/g, ' and ')
      .replace(/\s\s+/g, ' ');
  }
}
