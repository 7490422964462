import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
export interface RrsToast {
  toastHeaderText?: string;
  toastBody?: string;
  toastButtonText?: string;
  toastPostion?: string;
  headerIconFileName?: string; // left-bottom, left-top, left-center, right-bottom, right-top, right-center,center
}
export class RrsToastService {
  public showsToast$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public toastObj$: BehaviorSubject<RrsToast> = new BehaviorSubject<RrsToast>(
    {}
  );

  callbackResponse = new Subject();
  showToast(toastObj: RrsToast): void {
    this.showsToast$.next(true);
    this.toastObj$.next(toastObj);
  }

  dismissToast(): void {
    this.callbackResponse.next(true);
    this.showsToast$.next(false);
  }
}
