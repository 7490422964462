import { isPlatformBrowser } from '@angular/common';
import { ApplicationRef, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { RrsToastService } from '@app/custom/features/rrs-toast/services/rrs-toast.service';
import { combineLatest, interval } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RrsCheckForUpdateService {
  constructor(
    appRef: ApplicationRef,
    updates: SwUpdate,
    protected rrsToastService: RrsToastService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const everyHour$ = interval(600 * 6 * 1000);
      combineLatest([appRef.isStable, everyHour$]).subscribe(
        async ([res1, res2]) => {
          try {
            const updateFound = await updates.checkForUpdate();

            if (updateFound) {
              this.showToast();
            }
          } catch (err) {
            console.error('Failed to check for updates:', err);
          }
        }
      );
    }
  }

  showToast(): void {
    this.rrsToastService.showToast({
      toastHeaderText: 'New update!',
      toastBody: 'Refresh to have the latest features.',
      toastButtonText: 'Refresh',
      toastPostion: 'left-bottom',
      headerIconFileName: 'arrows-rotate.svg',
    });
    this.rrsToastService.callbackResponse.pipe(take(1)).subscribe((res) => {
      if (res) window.location.reload();
    });
  }
}
