import { Injectable } from '@angular/core';
import { RrsAuthService } from '@app/custom/features/rrs-auth/services/rrs-auth.service';
import { Store } from '@ngrx/store';
import {
  CsAgentAuthService,
  AsmAuthStorageService,
  TokenTarget,
} from '@spartacus/asm/root';
import {
  AuthService,
  UserIdService,
  OAuthLibWrapperService,
  AuthActions,
  OCC_USER_ID_CURRENT,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { UserProfileFacade } from '@spartacus/user/profile/root';

@Injectable({
  providedIn: 'root',
})
export class RrsCsAgentAuthService extends CsAgentAuthService {
  constructor(
    protected authService: AuthService,
    protected authStorageService: AsmAuthStorageService,
    protected userIdService: UserIdService,
    protected oAuthLibWrapperService: OAuthLibWrapperService,
    protected store: Store,
    protected userProfileFacade: UserProfileFacade,
    protected rrsAuthService: RrsAuthService,
    protected userAccountFacade: UserAccountFacade
  ) {
    super(
      authService,
      authStorageService,
      userIdService,
      oAuthLibWrapperService,
      store,
      userProfileFacade,
      userAccountFacade
    );
  }

  async logoutCustomerSupportAgent(): Promise<void> {
    const emulatedToken = this.authStorageService.getEmulatedUserToken();

    let isCustomerEmulated;
    this.userIdService
      .isEmulated()
      .subscribe((emulated) => (isCustomerEmulated = emulated))
      .unsubscribe();

    await this.oAuthLibWrapperService.revokeAndLogout();

    this.store.dispatch({ type: '[Auth] Logout Customer Support Agent' });
    this.authStorageService.setTokenTarget(TokenTarget.User);

    if (isCustomerEmulated && emulatedToken) {
      this.store.dispatch(new AuthActions.Logout());
      this.authStorageService.setToken(emulatedToken);
      this.userIdService.setUserId(OCC_USER_ID_CURRENT);
      this.authStorageService.clearEmulatedUserToken();
      this.store.dispatch(new AuthActions.Login());
    } else {
      this.rrsAuthService.signOut();
    }
  }
}
