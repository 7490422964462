import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ModalModule } from '@app/custom/features/modal';
import { RrsMediaModule } from '@app/custom/features/rrs-media/rrs-media.module';
import { RrsProductCardModule } from '@app/custom/features/rrs-product-card/rrs-product-card.module';
import { RrsProductVariantsModule } from '@app/custom/features/rrs-product-variants/rrs-product-variants.module';
import { RrsStarRatingModule } from '@app/custom/features/rrs-star-rating/rrs-star-rating.module';
import { RrsSharedPipesModule } from '@app/shared/pipes';
import { I18nModule, UrlModule } from '@spartacus/core';
import { SwiperModule } from 'swiper/angular';
import { RrsShopLookMiniComponent } from '@app/custom/features/rrs-shop-look/components/rrs-shop-look-mini/rrs-shop-look-mini.component';
import { PageSlotModule } from '@spartacus/storefront';
import { RrsShopLookService } from '@app/custom/features/rrs-shop-look/services/rrs-shop-look.service';
import { RrsIconModule } from '@app/shared/components';
import { RrsGlobalMessageModule } from '@app/custom/features/rrs-global-message';
export function initShopLookService(injector: Injector): () => void {
  const result = (): void => {
    const service = injector.get(RrsShopLookService);
    service.createShopLookElement();
  };
  return result;
}

@NgModule({
  declarations: [RrsShopLookMiniComponent],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    UrlModule,
    SwiperModule,
    RrsMediaModule,
    RrsSharedPipesModule,
    RrsProductVariantsModule,
    ModalModule,
    RrsStarRatingModule,
    RrsProductCardModule,
    PageSlotModule,
    RrsIconModule,
    RrsGlobalMessageModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initShopLookService,
      deps: [Injector],
      multi: true,
    },
  ],
})
export class RrsShopLookModule {}
