import { Injectable } from '@angular/core';
import { CartAdapter } from '@spartacus/cart/base/core';
import { OccCartAdapter } from '@spartacus/cart/base/occ';
import { Cart, CART_NORMALIZER } from '@spartacus/cart/base/root';
import {
  Occ,
  OCC_CART_ID_CURRENT,
  backOff,
  isJaloError,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RrsOccCartAdapter extends OccCartAdapter implements CartAdapter {
  override load(userId: string, cartId: string): Observable<Cart | undefined> {
    if (cartId === OCC_CART_ID_CURRENT) {
      return this.loadAll(userId).pipe(
        map((carts) => carts.find((cart) => cart['saveTime'] === undefined))
      );
    } else {
      return this.http
        .get<Occ.Cart>(
          this.occEndpointsService.buildUrl('cart', {
            urlParams: { userId, cartId },
          })
        )
        .pipe(
          backOff({
            shouldRetry: isJaloError,
          }),
          this.converterService.pipeable(CART_NORMALIZER)
        );
    }
  }
}
