<cx-icon
  *ngFor="let i of [1, 2, 3, 4, 5]"
  class="icon-star"
  [ngClass]="{ fill: fillStar(i) }"
  [type]="icon"
  [style.font-size]="size"
  [style.-webkit-text-stroke-width]="outlineThickness + 'px'"
  (mouseover)="setRate(i)"
  (click)="saveRate(i)"
  (keydown.space)="saveRate(i)"
  [attr.tabindex]="disabled ? null : 0">
</cx-icon>
