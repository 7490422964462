export const basesitesConfig = {
  baseSites: [
    {
      channel: 'B2C',
      defaultLanguage: {
        active: true,
        isocode: 'en',
        name: 'English',
        nativeName: 'English',
      },
      locale: 'en_US',
      name: 'RRS Basesite',
      theme: 'rrsTheme',
      uid: 'rrsBasesite',
    },
  ],
};
