<div class="p-3 d-none d-lg-flex">
  <cx-media
    class="col-3"
    [container]="{
      url: item.thumbnailUrl
    }"
    [alt]="item.itemDescription"></cx-media>
  <div class="col-3">
    <div class="fw-semibold">{{ item.brand }}</div>
    <div class="fw-semibold">{{ item.itemDescription }}</div>
    <div>{{ item.size }}&nbsp;{{ item.width }}</div>
    <div>{{ item.color }}</div>
  </div>
  <div class="col-4">
    <div>{{ 'rrs.orders.status' | cxTranslate }}: {{ item.status }}</div>
    <div *ngIf="item.trackingNumber" class="mb-3">
      {{ 'rrs.orders.tracking' | cxTranslate }}:
      <a
        class="text-decoration-underline"
        href="https://www.fedex.com/fedextrack/?tracknumbers={{
          item.trackingNumber
        }}&cntry_code=us"
        >{{ item.trackingNumber }}</a
      >
    </div>
    <ng-content *ngTemplateOutlet="address"></ng-content>
  </div>
  <div class="col-2 fs-3 text-end">
    {{ item.originalNetPrice + item.originalSalesTax | currency }}
  </div>
</div>

<div class="d-lg-none">
  <div class="d-flex mb-3">
    <cx-media
      class="col-4"
      [container]="{
        url:
          'https://deichmann.scene7.com/asset/deichmann/US_01_' +
          item.sku +
          '_01?$rr_results$&amp;defaultImage=default_obs'
      }"></cx-media>
    <div class="col-5">
      <div class="fw-semibold">{{ item.brand }}</div>
      <div class="fw-semibold">{{ item.itemDescription }}</div>
      <div>{{ item.size }}&nbsp;{{ item.width }}</div>
      <div>{{ item.color }}</div>
    </div>
    <div class="col-3 fs-3 text-end fw-bold">
      {{ item.originalNetPrice + item.originalSalesTax | currency }}
    </div>
  </div>

  <div class="mb-3">
    <h5 class="fs-6 p-2 text-bg-light">
      {{ 'rrs.orders.statusAndTracking' | cxTranslate }}
    </h5>
    <div class="d-flex">
      <div>
        <div>{{ 'rrs.orders.status' | cxTranslate }}: {{ item.status }}</div>
        <div>
          {{ 'rrs.orders.tracking' | cxTranslate }}:
          <a
            class="text-decoration-underline"
            href="https://www.fedex.com/fedextrack/?tracknumbers={{
              item.trackingNumber
            }}&cntry_code=us"
            >{{ item.trackingNumber }}</a
          >
        </div>
      </div>
    </div>
  </div>
  <ng-content *ngTemplateOutlet="address"></ng-content>
</div>

<ng-template #address>
  <div
    *ngIf="item.shipType !== 'PICKUP'"
    class="p-2 border border-light border-opacity-50 rounded-1">
    <button
      (click)="collapse.toggle()"
      [attr.aria-expanded]="!isCollapsed"
      [ngClass]="{ collapsed: isCollapsed }"
      type="button"
      class="w-100 d-flex align-items-center justify-content-between">
      <span *ngIf="item.shippingAddress" class="me-3">{{
        'rrs.orders.shipTo' | cxTranslate
      }}</span>
      <span *ngIf="!item.shippingAddress" class="me-3">{{
        'rrs.orders.pickUpFrom' | cxTranslate
      }}</span>
      <rrs-icon class="chevron" [icon]="icon.CHEVRON_UP" [size]="14"></rrs-icon>
    </button>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <ng-container
        *ngIf="item.shippingAddress as shippingAddress; else pickupAddress">
        <div class="mt-2">
          {{ shippingAddress.firstName }}&nbsp;{{ shippingAddress.lastName }}
        </div>
        <div>{{ shippingAddress.line1 }}</div>
        <div *ngIf="shippingAddress.line2">{{ shippingAddress.line2 }}</div>
        <div>
          {{ shippingAddress.town }}&nbsp;{{
            shippingAddress.region?.isocode
          }}&nbsp;{{ shippingAddress.postalCode }}
        </div>
      </ng-container>
      <!-- TODO: Once we have pickup orders verify the pickup address -->
      <ng-template #pickupAddress>
        <div class="mt-2">
          {{ storeAddress.companyName }}
        </div>
        <div>{{ storeAddress.town }}</div>
        <div>{{ storeAddress.region?.isocode }}</div>
      </ng-template>
    </div>
  </div>
</ng-template>
