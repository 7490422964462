import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  GRID_ITEMS_DESKTOP,
  GRID_ITEMS_MOBILE,
} from '@app/custom/features/rrs-product-variants/components/rrs-product-variants-colors/configs/productGrid.config';
import { RrsShopLookService } from '@app/custom/features/rrs-shop-look/services/rrs-shop-look.service';
import { Scene7Presets } from '@app/shared/configs/scene7.config';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { RrsProductScope } from '@app/spartacus/configurations/types.config';
import {
  Image,
  Images,
  isNotUndefined,
  Product,
  ProductService,
  RoutingService,
} from '@spartacus/core';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { combineLatest, of, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-product-variants-colors',
  styleUrls: ['./rrs-product-variants-colors.component.scss'],
  templateUrl: './rrs-product-variants-colors.component.html',
})
export class RrsProductVariantsColorsComponent implements OnInit {
  @Input() product!: Product;
  codeOfVariantBeingLoaded: null | string = null;
  visibleColorVariants: Product[] = [];
  collapseColorVariants: Product[] = [];
  subscriptions = new Subscription();
  activeSlide = 0;
  public isCollapsed = true;
  icon = ICON_TYPE_LIST;

  constructor(
    protected productService: ProductService,
    protected routingService: RoutingService,
    protected breakpintService: BreakpointService,
    protected cd: ChangeDetectorRef,
    protected rrsShopLookService: RrsShopLookService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([
        of(this.product),
        this.breakpintService.isDown(BREAKPOINT.lg),
      ]).subscribe(([product, isMobile]) => {
        const productsInRow =
          isMobile || this.rrsShopLookService.shopLookOpen
            ? GRID_ITEMS_MOBILE
            : GRID_ITEMS_DESKTOP;
        this.visibleColorVariants =
          product?.siblings?.slice(0, productsInRow) || [];
        this.collapseColorVariants =
          product?.siblings?.slice(productsInRow) || [];
        this.activeSlide =
          product?.siblings?.findIndex((sibling) => {
            return sibling.code === product.code;
          }) || 0;
        this.cd.detectChanges();
      })
    );
  }

  getColorImage(images: Images): string {
    return (
      (images[0] as Image)?.url?.replace(
        Scene7Presets.MAIN,
        Scene7Presets.COLOR
      ) || ''
    );
  }

  setColor(code: string): null {
    this.codeOfVariantBeingLoaded = code;
    if (code) {
      this.productService
        .get(code, RrsProductScope.ALL)
        .pipe(filter(isNotUndefined), take(1))
        .subscribe((product: Product) => {
          if (this.rrsShopLookService.shopLookOpen) {
            this.rrsShopLookService.openPDP(product.code);
          } else {
            this.routingService
              .go({
                cxRoute: 'product',
                params: product,
              })
              .then(() => {
                this.codeOfVariantBeingLoaded = null;
              });
          }
        });
    }
    return null;
  }
}
