import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '@environments/environment';
import { RrsDynamicYieldService } from '@app/custom/features/rrs-dynamic-yield/services/rrs-dynamic-yield.service';
import { RrsOnetrustService } from '@app/custom/features/rrs-onetrust/rrs-onetrust.service';
import { Subscription } from 'rxjs';
import { scriptsCookieGroups } from '@app/custom/features/rrs-onetrust/rrs-onetrust.config';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [RrsDynamicYieldService, RrsOnetrustService],
      useFactory: rrsDynamicYieldInitializer,
      multi: true,
    },
  ],
})
export class RrsDynamicYieldModule {}

function rrsDynamicYieldInitializer(
  rrsDynamicYieldService: RrsDynamicYieldService,
  rrsOnetrustService: RrsOnetrustService
) {
  return (): Subscription =>
    rrsOnetrustService
      .checkConsents(scriptsCookieGroups.dynamicYield)
      .subscribe((hasConsent) => {
        if (!hasConsent || !environment.dynamicYield.enabled) return;
        rrsDynamicYieldService.initialize();
      });
}
