<ng-container *ngIf="{ store: store$ | async } as data">
  <div
    class="pickup"
    [attr.aria-label]="
      'rrs.product.deliveryOptions.inStorePickup' | cxTranslate
    ">
    <div class="d-flex align-items-center flex-grow-1 h-100">
      <input
        class="form-check-input me-3 d-none"
        type="radio"
        (change)="selectFulfillment()"
        (keydown.enter)="selectFulfillment()"
        [checked]="fulfillment.selectedMethod === deliveryOptions.PICKUP"
        id="pickupInStoreRadio" />
      <label
        class="form-check-label pickup__label h-100 opacity-100 cursor-pointer d-flex align-items-center flex-grow-1"
        for="pickupInStoreRadio">
        <div
          class="d-flex flex-column justify-content-between align-items-start flex-grow-1 gap-2">
          <p class="pickup__title mb-0 fw-bold d-flex gap-1 align-items-center">
            <img
              [attr.alt]="'bag-shopping.svg'"
              [attr.src]="'/assets/icons/bag-shopping.svg'"
              height="16"
              width="16" />
            {{
              (data.store
                ? 'rrs.product.deliveryOptions.inStorePickup'
                : 'rrs.product.deliveryOptions.pickup'
              ) | cxTranslate
            }}<span class="text-accent">*</span>
          </p>
          <p
            *ngIf="data.store"
            [ngClass]="
              data.store.productStock?.stockLevel > 0 ||
              data.store.productStock?.stockLevelStatus ===
                stockLevelStatus.IN_STOCK
                ? 'text-success'
                : 'text-danger'
            "
            class="my-0">
            {{
              data.store.productStock?.stockLevel > 0
                ? ('rrs.product.deliveryOptions.numberInStock'
                  | cxTranslate
                    : {
                        stockLevel: data.store.productStock?.stockLevel
                      })
                : data.store.productStock?.stockLevelStatus ===
                  stockLevelStatus.IN_STOCK
                ? ('rrs.product.deliveryOptions.inStock' | cxTranslate)
                : ('rrs.product.deliveryOptions.storeOutOfStock' | cxTranslate)
            }}
          </p>
          <div class="pickup__title mb-0" *ngIf="data.store">
            <ng-container
              *ngTemplateOutlet="
                data.store.productStock?.stockLevelStatus ===
                stockLevelStatus.IN_STOCK
                  ? earnPointsMessage
                  : checkNearbyLink
              ">
            </ng-container>
          </div>
          <div
            class="pickup__store-details my-1 pickup__sub_title"
            *ngIf="!data.store">
            <p class="my-0">
              {{ 'rrs.product.deliveryOptions.selectStoreMsg' | cxTranslate }}
            </p>
          </div>
        </div>
      </label>
    </div>
  </div>
  <ng-template #earnPointsMessage>
    <p>{{ 'rrs.product.deliveryOptions.earnMessage' | cxTranslate }}</p>
  </ng-template>
  <ng-template #checkNearbyLink>
    <a
      class="text-decoration-underline check-nearby"
      (click)="openFindStoresDialog($event)"
      (keypress)="openFindStoresDialog($event)">
      {{ 'rrs.product.deliveryOptions.checkNearBy' | cxTranslate }}</a
    >
  </ng-template>
</ng-container>
