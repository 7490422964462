import { PaginationModel, PointOfService, Time } from '@spartacus/core';
import { StoreEntities } from '@spartacus/storefinder/root';

export interface RrsPointOfService extends PointOfService {
  pickupEnabled?: boolean;
  curbsidePickupEnabled?: boolean;
  isOffBroadway?: boolean;
  phoneNumber?: string;
  productStock?: { stockLevel?: number };
  statusDate?: string;
  nowOpen?: boolean;
  comingSoon?: boolean;
}

export interface RrsStoreOpenState {
  opened: boolean;
  openTime?: Time;
  closeTime?: Time;
}

export interface RrsStoreEntities extends StoreEntities {
  pagination?: PaginationModel;
  stores?: RrsPointOfService[];
}

export interface SelectedStoreDialogData {
  store: RrsPointOfService;
  setAsDefault: boolean;
  currentSearchQuery: string;
}

export interface SpecificStoresList {
  openedRecently: RrsPointOfService[];
  comingSoon: RrsPointOfService[];
}

export interface CmsStoreLocation {
  name: string;
  phone: string;
  store_id: string;
}

export enum StoreFeatures {
  IS_OB_WAREHOUSE = 'storeOBSignage',
  PHONE = 'phone',
  CURBSIDE_PICKUP_ENABLED = 'curbsidePickup',
  IS_PICKUP_ENABLED = 'bopuisEligible',
  STATUS_DATE = 'statusDate',
}

export interface UpdatedStores {
  pointOfServices: RrsPointOfService[];
}
