import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { RrsSavedPaymentsService } from '@app/custom/features/rrs-account/components/rrs-saved-payments/services/rrs-saved-payments.service';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import {
  RrsPayment,
  RrsPaymentTypes,
} from '@app/custom/features/rrs-checkout/model';
import { preparePaymentEndpointData } from '@app/shared/utils/cart.helper';
import {
  clearRegionUSPrefix,
  decorateRegionWithUSPrefix,
} from '@app/shared/utils/common';
import { AsmEnablerService } from '@spartacus/asm/root';
import { Cart } from '@spartacus/cart/base/root';
import {
  CheckoutDeliveryAddressFacade,
  CheckoutDeliveryModesFacade,
} from '@spartacus/checkout/base/root';
import {
  Address,
  AuthService,
  backOff,
  isJaloError,
  normalizeHttpError,
  OCC_USER_ID_ANONYMOUS,
  OccEndpointsService,
  PaymentDetails,
  UserIdService,
  UserPaymentService,
} from '@spartacus/core';
import { AddressBookComponentService } from '@spartacus/storefront';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsCheckoutService {
  public expressCheckoutInProgress = false;
  private billingAddress$: BehaviorSubject<Address | null> =
    new BehaviorSubject<Address | null>(null);

  paymentMethods$ = this.userPaymentService.getPaymentMethodsLoading().pipe(
    filter((isLoading) => !isLoading),
    take(1),
    switchMap(() => this.userPaymentService.getPaymentMethods())
  );

  // to track if checkout was run at least once per session.
  checkoutFirstStart = true;

  userAddresses$ = this.addressBookService.getAddressesStateLoading().pipe(
    filter((isLoading) => !isLoading),
    take(1),
    switchMap(() => this.addressBookService.getAddresses())
  );

  private authService: AuthService = inject(AuthService);

  constructor(
    protected activeCartService: RrsActiveCartService,
    protected addressBookService: AddressBookComponentService,
    protected userPaymentService: UserPaymentService,
    protected userIdService: UserIdService,
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    protected savedPaymentsService: RrsSavedPaymentsService,
    protected asmEnablerService: AsmEnablerService
  ) {}

  checkExpressCheckoutAvailable(): Observable<boolean> {
    this.userPaymentService.loadPaymentMethods();
    this.addressBookService.loadAddresses();
    return combineLatest([
      this.activeCartService.takeActive(),
      this.userAddresses$,
      this.paymentMethods$,
    ]).pipe(
      take(1),
      map(
        ([, addresses, savedPayments]) =>
          Boolean(addresses?.find((address: any) => address.defaultAddress)) &&
          Boolean(
            savedPayments?.find((payment: any) => payment.defaultPaymentInfo)
          )
      )
    );
  }

  setAddress(customAddress?: Address): Observable<null> {
    return customAddress
      ? this.checkoutPreconditions().pipe(
          switchMap(([userId, cartId]) =>
            this.checkoutDeliveryAddressFacade
              .createAndSetAddress(customAddress)
              .pipe(map(() => null))
          )
        )
      : this.checkoutPreconditions().pipe(
          switchMap(([userId, cartId]) => {
            return this.addressBookService.getAddresses().pipe(
              take(1),
              map((addresses: Address[]) =>
                addresses?.find((address) => address.defaultAddress)
              ),
              switchMap((address) =>
                this.saveAddress(userId, cartId, address?.id)
              )
            );
          })
        );
  }

  setDeliveryMode(): Observable<unknown> {
    return this.checkoutDeliveryModesFacade.getSupportedDeliveryModes().pipe(
      filter((deliveryModes) => !!deliveryModes?.length),
      distinctUntilChanged((current, previous) => {
        return JSON.stringify(current) === JSON.stringify(previous);
      }),
      take(1),
      switchMap((deliveryModes) =>
        this.checkoutDeliveryModesFacade.setDeliveryMode(
          deliveryModes[0]?.code!
        )
      )
    );
  }

  setPaymentType(): Observable<PaymentDetails | undefined> {
    return this.userPaymentService.getPaymentMethods().pipe(
      map((paymentDetails) => {
        return paymentDetails.find(
          (paymentDetail: any) => paymentDetail.defaultPaymentInfo
        );
      }),
      switchMap((paymentInfo) =>
        this.savedPaymentsService
          .usePayment(paymentInfo)
          .pipe(tap(() => this.activeCartService.reloadActiveCart()))
      )
    );
  }

  doExpressCheckout(paypalPaymentDetails: any): Observable<any> {
    const addressFromPaypal = paypalPaymentDetails?.details?.shippingAddress;
    let customAddress = addressFromPaypal
      ? {
          ...addressFromPaypal,
          phone: paypalPaymentDetails?.details?.phone?.replaceAll('-', ''),
          email: paypalPaymentDetails?.details?.email,
          firstName: paypalPaymentDetails?.details.firstName,
          lastName: paypalPaymentDetails?.details.lastName,
          town: addressFromPaypal?.city,
          region: {
            isocode: decorateRegionWithUSPrefix(addressFromPaypal?.state),
            isocodeShort: clearRegionUSPrefix(addressFromPaypal?.state),
          },
          country: {
            isocode: 'US',
          },
          shippingAddress: true,
        }
      : undefined;
    return this.setAddress(customAddress).pipe(
      switchMap(() =>
        combineLatest([
          this.setDeliveryMode(),
          paypalPaymentDetails ? of(true) : this.setPaymentType(),
        ])
      )
    );
  }

  protected checkoutPreconditions(): Observable<[string, string]> {
    return combineLatest([
      this.userIdService.takeUserId(),
      this.activeCartService.takeActiveCartId(),
      this.activeCartService.isGuestCart(),
    ]).pipe(
      take(1),
      map(([userId, cartId, isGuestCart]) => {
        if (
          !userId ||
          !cartId ||
          (userId === OCC_USER_ID_ANONYMOUS && !isGuestCart)
        ) {
          throw new Error('Checkout conditions not met');
        }
        return [userId, cartId];
      })
    );
  }

  protected saveAddress(
    userId: string,
    cartId: string,
    addressId?: string
  ): Observable<null> {
    return this.http
      .put<null>(
        this.getSetDeliveryAddressEndpoint(userId, cartId, addressId),
        {}
      )
      .pipe(
        catchError((error) => throwError(normalizeHttpError(error))),
        backOff({
          shouldRetry: isJaloError,
        })
      );
  }

  protected getSetDeliveryAddressEndpoint(
    userId: string,
    cartId: string,
    addressId?: string
  ): string {
    return this.occEndpoints.buildUrl('setDeliveryAddress', {
      urlParams: { userId, cartId },
      queryParams: { addressId },
    });
  }

  removePayment(paymentInfoId: string): void {
    combineLatest([
      this.activeCartService.getActive(),
      this.authService.isUserLoggedIn(),
    ])
      .pipe(take(1))
      .subscribe(([cart, userLogged]) => {
        let { cartId, userId, headers } = preparePaymentEndpointData(
          cart,
          userLogged,
          this.asmEnablerService.isEnabled()
        );

        const url = this.occEndpoints.buildUrl(
          `users/${userId}/carts/${cartId}/paymentdetails/${paymentInfoId}`
        );
        this.http
          .delete(url, { headers })
          .pipe(take(1))
          .subscribe(() => {
            this.activeCartService.reloadActiveCart();
          });
      });
  }

  setBillingAddress(billingAddress: Address): void {
    this.billingAddress$.next(billingAddress);
  }

  getBillingAddress$(): Observable<Address | null> {
    return this.billingAddress$.asObservable();
  }
}
