import { Injectable } from '@angular/core';
import { RrsPayment } from '@app/custom/features/rrs-checkout/model';
import { CardRewards } from '@app/custom/features/rrs-rewards/models/rewards.model';
import {
  RrsAddNewShippingAddressEvent,
  RrsAutoCompleteProductClickedEvent,
  RrsEditAccountInfoEvent,
  RrsEditShippingAddressEvent,
  RrsEmailSignupSuccessEvent,
  RrsErrorNotificationEvent,
  RrsExpressCheckoutEvent,
  RrsFacetChangeEvent,
  RrsFacetClickedEvent,
  RrsFacetViewedEvent,
  RrsInternalSearchClickEvent,
  RrsKlarnaPaymentEvent,
  RrsLiveChatClickEvent,
  RrsLoginSuccessEvent,
  RrsOrderPlacedEvent,
  RrsRegistrationStartEvent,
  RrsRegistrationSuccessEvent,
  RrsSocialLinkClickEvent,
  RrsSortChangeEvent,
} from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/model/events';
import { sha256Encode } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/services/utils/utils';
import { titleCase } from '@app/shared/utils/string';
import { environment } from '@environments/environment';
import { OrderEntry } from '@spartacus/cart/base/root';
import { CheckoutQueryResetEvent } from '@spartacus/checkout/base/root';
import { EventService, Product, User, WindowRef } from '@spartacus/core';
import { Order } from '@spartacus/order/root';
import { NavigationEvent } from '@spartacus/storefront';
import { UserAccountService } from '@spartacus/user/account/core';
import { take, takeWhile } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RrsEventsDispatcherService {
  constructor(
    protected eventService: EventService,
    protected userAccountService: UserAccountService,
    protected winRef: WindowRef
  ) {}

  dispatchLiveChatEvent(): void {
    this.eventService.dispatch(
      {
        event: [
          {
            eventInfo: {
              eventName: 'live chat link',
              eventAction: 'event33,event44',
              eventPage: 'live chat link',
            },
          },
        ],
      },
      RrsLiveChatClickEvent
    );
  }

  dispatchAddNewShippingAddressEvent(): void {
    this.eventService.dispatch(
      {
        event: [
          {
            page: {
              pageInfo: {
                pageName: 'my account|my shipping|add new shipping address',
              },
              category: {
                pageType: 'my account',
                primaryCategory: 'my account',
                subCategory1: 'my account|my shipping',
                subCategory2:
                  'my account|my shipping|add new shipping addresse',
              },
            },
          },
        ],
      },
      RrsAddNewShippingAddressEvent
    );
  }

  dispatchEditShippingAddressEvent(): void {
    this.eventService.dispatch(
      {
        event: [
          {
            page: {
              pageInfo: {
                pageName: 'my account|my shipping addresses page',
              },
              category: {
                pageType: 'my account',
                primaryCategory: 'my account',
                subCategory1: 'my account|my shipping addresses page',
              },
            },
          },
        ],
      },
      RrsEditShippingAddressEvent
    );
  }

  dispatchEditAccountInfoEvent(): void {
    this.eventService.dispatch(
      {
        event: [
          {
            page: {
              pageInfo: {
                pageName: 'my account|edit your account info page',
              },
              category: {
                pageType: 'my account',
                primaryCategory: 'my account',
                subCategory1: 'my account|edit your account info page',
              },
            },
          },
        ],
      },
      RrsEditAccountInfoEvent
    );
  }

  dispatchSocialLinkClick(socialNetwork: string): void {
    const socialLink = 'Social footer links|' + socialNetwork;
    this.eventService.dispatch(
      {
        event: [
          {
            eventInfo: {
              eventName: socialLink,
              eventAction: 'event39',
              eventPage: 'Footer',
            },
          },
        ],
      },
      RrsSocialLinkClickEvent
    );
  }

  dispatchInternalSearchClick(product: Product, position: number): void {
    this.eventService.dispatch(
      {
        event: [
          {
            eventInfo: {
              eventName: 'internal search clicks',
              eventAction: 'event14, event44',
              eventPage: 'internal search|search results page',
            },
            product: {
              productInfo: {
                sku: product?.code || '',
              },
            },
          },
        ],
        algolia: {
          eventName: 'PLP: Product Clicked',
          objectIDs: [product.objectID || ''],
          indexName: environment.algolia.indexName,
          positions: [position],
          queryID: product.queryID,
        },
        customEvent: new CustomEvent('rrsstorefront.plpProductClicked'),
      },
      RrsInternalSearchClickEvent
    );
  }

  dispatchProductAutoCompleteSelectedEvent(
    product: Product,
    position: number
  ): void {
    this.eventService.dispatch(
      {
        event: [
          {
            eventInfo: {
              eventName: 'rrs autocomplete search product clicked',
              eventAction: 'productAutoCompleteClick',
              eventPage: 'autocomplete search',
            },
            product: {
              productInfo: {
                sku: product?.code || '',
              },
            },
          },
        ],
        algolia: {
          eventName: 'Autocomplete: Product Clicked',
          objectIDs: [product.objectID || ''],
          indexName: environment.algolia.indexName,
          positions: [position],
          queryID: product.queryID,
        },
        customEvent: new CustomEvent(
          'rrsstorefront.autoCompleteProductClicked'
        ),
      },
      RrsAutoCompleteProductClickedEvent
    );
  }
  dispatchExpressCheckoutEvent(
    products: { productInfo: { sku: string } }[]
  ): void {
    this.eventService.dispatch(
      {
        event: [
          {
            eventInfo: {
              eventName: 'express checkout',
              eventAction: 'event90',
              eventPage: 'checkout options page',
            },
          },
          ...products,
        ],
      },
      RrsExpressCheckoutEvent
    );
  }

  dispatchSortEvent(sortName = 'relevance'): void {
    this.eventService.dispatch(
      {
        event: [
          {
            eventInfo: {
              eventName: 'product sorting',
              eventAction: sortName,
              eventPage: 'product listing',
            },
          },
        ],
      },
      RrsSortChangeEvent
    );
  }

  dispatchFacetChangeEvent(facet: string, eventName: 'add' | 'remove'): void {
    this.eventService
      .get(NavigationEvent)
      .pipe(take(1))
      .subscribe((event) => {
        const isCategoryPage =
          event.context?.id === 'search' && !event.url?.includes('/search');
        const eventPage = isCategoryPage ? 'category page' : 'search page';
        const eventAction =
          eventName === 'add' ? 'filter applied' : 'filter removed';
        this.eventService.dispatch(
          {
            event: [
              {
                eventInfo: {
                  eventName: facet,
                  eventAction,
                  eventPage,
                },
              },
            ],
          },
          RrsFacetChangeEvent
        );
      });
  }

  dispatchFacetViewedEvent(facets: string[]): void {
    this.eventService.dispatch(
      {
        event: [
          {
            eventInfo: {
              eventName: 'facet-viewed',
              eventAction: 'facetViewed',
              eventPage: 'search|category-page',
            },
          },
        ],
        algolia: {
          eventName: 'PLP: Filter Viewed',
          indexName: environment.algolia.indexName,
          filters: facets,
        },
        customEvent: new CustomEvent('rrsstorefront.plpFilterViewed'),
      },
      RrsFacetViewedEvent
    );
  }

  dispatchFacetClickEvent(facet: string): void {
    this.eventService.dispatch(
      {
        event: [
          {
            eventInfo: {
              eventName: 'facet-clicked',
              eventAction: 'facetClicked',
              eventPage: 'search|category-page',
            },
          },
        ],
        algolia: {
          eventName: 'PLP: Filter Clicked',
          indexName: environment.algolia.indexName,
          filters: [facet],
        },
        customEvent: new CustomEvent('rrsstorefront.plpFilterClicked'),
      },
      RrsFacetClickedEvent
    );
  }

  dispatchOrderPlacedEvent(orderData: Order): void {
    const transaction = {
      transactionID: orderData.code ?? '',
      cart_id: orderData.guid ?? '',
      item: (orderData.entries ?? []).map((entry) => ({
        productInfo: {
          sku: entry.product?.baseProduct ?? '',
          productBrand:
            entry.product?.categories?.find((category) =>
              category.code?.includes('brand')
            )?.name ?? '',
          units: entry.quantity?.toString() ?? '',
          checkoutPrice: entry.totalPrice?.value ?? 0,
          originalPrice:
            entry.basePrice?.value ??
            entry.product?.baseOptions?.[0].selected?.priceData?.value ??
            0,
          prodShipMethod: entry.deliveryPointOfService ? 'pickup' : 'delivery',
        },
      })),
      attributes: {
        paymentMethod: this.setPaymentMethod(orderData.paymentTenderDataList),
        paymentGateway: this.setPaymentGateway(orderData.paymentTenderDataList),
        shippingMethod: orderData.deliveryMode?.name ?? '',
        manualVoucherCode: orderData.appliedVouchers?.[0]?.code ?? '',
        manualVoucherDiscount:
          orderData.appliedVouchers?.[0]?.value?.toFixed(2),
        autoVoucherCode: orderData.autoPromotionCodes ?? '',
        autoVoucherDiscount: orderData.autoPromotionDiscount?.toFixed(2),
        rewardPointsDiscount: this.calcTotalRewards(orderData.rewards)?.toFixed(
          2
        ),
      },
      total: {
        totalCartRevenue: this.calcTotalCartRevenue(orderData.entries),
        actualCartRevenue: orderData.totalPrice?.value,
        totalDiscount: orderData.totalDiscounts?.value,
        totalTaxPaid: orderData.totalTax?.value,
        totalShippingPaid: orderData.deliveryCost?.value,
        actualCostPaid: orderData.totalPriceWithTax?.value,
        totalRewards: this.calcTotalRewards(orderData.rewards),
        finalRevenue: this.calculateFinalRevenue(
          this.calcTotalCartRevenue(orderData.entries),
          orderData.totalDiscounts?.value
        ),
      },
    };
    const page = {
      pageInfo: {
        pageName: 'order checkout|order confirmation page',
        pageLoadEvent: 'purchase',
      },
      category: {
        pageType: 'checkout',
        primaryCategory: 'order checkout',
        subCategory1: 'order checkout|order confirmation',
        subCategory2: 'order checkout|order confirmation',
      },
    };

    this.eventService.dispatch(
      {
        customEvent: new CustomEvent('rrsstorefront.orderPlaced'),
        page,
        transaction,
      },
      RrsOrderPlacedEvent
    );
    this.eventService.dispatch({}, CheckoutQueryResetEvent);
  }

  private setPaymentMethod(
    paymentTenderDataList: RrsPayment[] | undefined
  ): string {
    const filteredPaymentList =
      paymentTenderDataList?.filter(
        (payment) => payment.tenderType !== 'RRGIFTCARD'
      ) ?? [];

    if (filteredPaymentList[0]?.tenderType === 'BTPP') {
      return 'paypal';
    }

    if (filteredPaymentList[0]?.tenderType === 'KL') {
      return 'Klarna';
    }

    return titleCase(filteredPaymentList[0]?.tenderType) ?? '';
  }

  private setPaymentGateway(
    paymentTenderDataList: RrsPayment[] | undefined
  ): string {
    const filteredPaymentList =
      paymentTenderDataList?.filter(
        (payment) => payment.tenderType !== 'RRGIFTCARD'
      ) ?? [];

    if (filteredPaymentList[0]?.tenderType === 'BTPP') {
      return 'braintree';
    }

    if (filteredPaymentList[0]?.tenderType === 'KL') {
      return 'Klarna';
    }

    if (filteredPaymentList[0]?.tenderType != '') {
      return 'cybersource';
    }

    return '';
  }

  private calcTotalRewards(rewards: CardRewards[] | undefined): number {
    return (
      rewards
        ?.filter((item) => item?.active)
        .reduce((prev, next) => prev + next.value, 0) ?? 0
    );
  }

  private calcTotalCartRevenue(entries: OrderEntry[] | undefined): number {
    return (
      entries
        ?.map(
          (entry) =>
            entry.basePrice?.value ??
            entry.product?.baseOptions?.[0].selected?.priceData?.value ??
            0
        )
        .reduce((prev, next) => prev + next, 0) ?? 0
    );
  }

  private calculateFinalRevenue(
    totalRevenue: number,
    discountValue: number = 0
  ): number {
    return totalRevenue - discountValue;
  }

  dispatchErrorNotificationEvent(message: string): void {
    this.eventService.dispatch(
      {
        customEvent: new CustomEvent<{ message: string }>(
          'rrsstorefront.errorNotification',
          {
            detail: {
              message,
            },
          }
        ),
      },
      RrsErrorNotificationEvent
    );
  }

  dispatchEmailSignupSuccessEvent(): void {
    this.eventService.dispatch({}, RrsEmailSignupSuccessEvent);
  }

  dispatchKlarnaPaymentEvent(): void {
    this.eventService.dispatch(
      {
        customEvent: new CustomEvent('rrsstorefront.klarnaPayment'),
      },
      RrsKlarnaPaymentEvent
    );
  }
  dispatchLoginSuccessEvent(): void {
    this.userAccountService
      .get()
      .pipe(takeWhile((user) => user === undefined, true))
      .subscribe(async (user: User | undefined) => {
        if (user) {
          this.eventService.dispatch(
            {
              form: {
                formInfo: {
                  formAction: 'event27',
                  formName: 'my account|rewards login page',
                },
              },
              user: {
                userInfo: {
                  authStatus: 'registered',
                  email: user.displayUid,
                  emailHash: await sha256Encode(user.displayUid),
                  phone: user.customerPhone,
                  loyaltyId: user.loyaltyId,
                  firstName: user.firstName,
                  lastName: user.lastName,
                },
              },
            },
            RrsLoginSuccessEvent
          );
        }
      });
  }

  dispatchRegistrationStartEvent(): void {
    this.eventService.dispatch(
      {
        form: {
          formInfo: {
            formAction: 'event27',
            formName: 'registration form',
          },
        },
      },
      RrsRegistrationStartEvent
    );
  }

  dispatchRegistrationSuccessEvent(): void {
    this.eventService.dispatch({}, RrsRegistrationSuccessEvent);
  }

  dispatchDYAddToCartEvent(productCode: string, price: number): void {
    if (this.winRef.nativeWindow?.DY?.API) {
      this.winRef.nativeWindow.DY.API('event', {
        name: 'Add to Cart',
        properties: {
          dyType: 'add-to-cart-v1',
          value: price,
          currency: 'USD',
          productId: productCode,
          quantity: 1,
        },
      });
    }
  }

  dispatchDYOrderPlacedEvent(orderData: Order): void {
    const cart = (orderData.entries ?? []).reduce((acc, curr) => {
      const productId = curr.product?.baseProduct ?? '';
      const quantity = curr.quantity ?? 0;
      const itemPrice = curr.totalPrice?.value ?? 0;

      if (acc[productId]) {
        acc[productId].quantity += quantity;
      } else {
        acc[productId] = {
          productId,
          quantity,
          itemPrice,
        };
      }
      return acc;
    }, {} as { [key: string]: { productId: string; quantity: number; itemPrice: number } });

    if (this.winRef.nativeWindow?.DY?.API) {
      this.winRef.nativeWindow.DY.API('event', {
        name: 'Purchase',
        properties: {
          uniqueTransactionId: orderData.code ?? '',
          dyType: 'purchase-v1',
          value: orderData.totalBasePrice?.value,
          currency: 'USD',
          cart: Object.values(cart),
        },
      });
    }
  }
}
