import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { RrsToastService } from '@app/custom/features/rrs-toast/services/rrs-toast.service';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RrsSwupdateUnrecoverableService {
  constructor(
    updates: SwUpdate,
    protected rrsToastService: RrsToastService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    if (isPlatformBrowser(this.platformId)) {
      updates.unrecoverable.subscribe((_res) => {
        this.showToast();
      });
    }
  }

  showToast(): void {
    this.rrsToastService.showToast({
      toastHeaderText: 'Uh oh!',
      toastBody:
        "There's been an unexpected error, please refresh to continue shopping.",
      toastButtonText: 'Refresh',
      toastPostion: 'left-bottom border-danger',
      headerIconFileName: 'red-circle-cross.svg',
    });
    this.rrsToastService.callbackResponse.pipe(take(1)).subscribe((res) => {
      if (res) window.location.reload();
    });
  }
}
