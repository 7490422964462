import { Injectable, inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { RrsImpervaService } from './services/rrs-imperva.service';
import { RrsImpervaCredentialsService } from './services/rrs-imperva-credentials.service';
import {
  OCC_PREFIX,
  IMPERVA_BYPASS_HEADER_NAME,
  ASSETS_PATH,
  ASM_PATH,
  OAUTH_PREFIX,
} from '@app/custom/features/rrs-imperva/rrs-imperva.config';
import { environment } from '@environments/environment';

const { impervaBypassHeaderValue } = environment;

@Injectable()
export class RrsImpervaBotProtectionInterceptor implements HttpInterceptor {
  protected impervaService = inject(RrsImpervaService);
  protected impervaCredentialsService = inject(RrsImpervaCredentialsService);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.includes(OCC_PREFIX) ||
      request.url.includes(ASSETS_PATH) ||
      request.url.includes(ASM_PATH) ||
      request.url.includes(OAUTH_PREFIX)
    ) {
      // Bypass bot protection for server-to-server requests
      const ssrBypassToken = this.impervaCredentialsService.getToken();
      if (ssrBypassToken != null) {
        const headers = request.headers.set(
          IMPERVA_BYPASS_HEADER_NAME,
          ssrBypassToken
        );

        const clonedRequest = request.clone({ headers });
        return next.handle(clonedRequest);
      }

      return from(this.impervaService.getToken()).pipe(
        switchMap((token) => {
          let headers = request.headers;

          // Add bot protection token to protected requests
          if (token) {
            headers = request.headers.set('x-d-token', token);
          }

          // Bypass bot protection in local development environments
          if (impervaBypassHeaderValue) {
            headers = request.headers.set(
              IMPERVA_BYPASS_HEADER_NAME,
              impervaBypassHeaderValue
            );
          }

          const clonedRequest = request.clone({ headers });
          return next.handle(clonedRequest);
        })
      );
    }
    return next.handle(request);
  }
}
