import { MediaComponent } from '@spartacus/storefront';
import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'rrs-media',
  templateUrl: 'rrs-media.component.html',
  styleUrls: ['rrs-media.component.scss'],
})
export class RrsMediaComponent extends MediaComponent implements OnChanges {
  @Input() isSearchBoxImg: boolean = false;
  @Input() width: string = '';
  @Input() height: string = '';
  @Input() priority = false;
  @Input() sizes: string = '';
  @Input() fit: string = '';
}
