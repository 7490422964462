import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StarRatingComponent } from '@spartacus/storefront';

@Component({
  selector: 'rrs-star-rating',
  templateUrl: './rrs-star-rating.component.html',
  styleUrls: ['./rrs-star-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsStarRatingComponent extends StarRatingComponent {
  @Input()
  size: string = '2rem';

  @Input()
  outlineThickness: number = 1;

  /**
   * The fillStar is used to determine how many stars should be filled in based on the rating. This only supports
   * filling the entire star, not partial or half filling.
   * @param starIndex number
   * @returns boolean
   */
  public fillStar(starIndex: number): boolean {
    return starIndex <= this.rating;
  }
}
