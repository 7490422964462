import { Injectable, Injector, isDevMode } from '@angular/core';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import {
  RrsAddNewShippingAddressEvent,
  RrsAddToCartEvent,
  RrsAddToCartSuccessEvent,
  RrsAddToWishlistEvent,
  RrsAutoCompleteProductClickedEvent,
  RrsCartPageEvent,
  RrsCartRemoveEntrySuccessEvent,
  RrsCategoryPageEvent,
  RrsCheckoutPageEvent,
  RrsCustomEvent,
  RrsEditAccountInfoEvent,
  RrsEditShippingAddressEvent,
  RrsEmailSignupSuccessEvent,
  RrsErrorNotificationEvent,
  RrsEvent,
  RrsExpressCheckoutEvent,
  RrsFacetChangeEvent,
  RrsFacetClickedEvent,
  RrsFacetViewedEvent,
  RrsGenericPageEvent,
  RrsInternalSearchClickEvent,
  RrsKlarnaPaymentEvent,
  RrsLiveChatClickEvent,
  RrsLoginSuccessEvent,
  RrsMyProfilePageEvent,
  RrsOrderPlacedEvent,
  RrsProductDetailsPageEvent,
  RrsRegistrationStartEvent,
  RrsRegistrationSuccessEvent,
  RrsRemoveFromCartEvent,
  RrsSearchPageEvent,
  RrsSocialLinkClickEvent,
  RrsSortChangeEvent,
} from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/model/events';
import { RrsAepCollectorService } from '@app/custom/features/rrs-tms/rrs-adobe-experience/rrs-aep.collector';
import { getEventUserInfo } from '@app/custom/features/rrs-tms/rrs-adobe-experience/utils/event.utils';
import { environment } from '@environments/environment';
import { EventService, User, UserIdService, WindowRef } from '@spartacus/core';
import {
  TmsCollectors,
  TmsConfig,
  TmsService,
} from '@spartacus/tracking/tms/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, merge } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RrsTmsService extends TmsService {
  constructor(
    eventsService: EventService,
    windowRef: WindowRef,
    tmsConfig: TmsConfig,
    injector: Injector,
    protected userIdService: UserIdService,
    protected userAccountService: UserAccountFacade,
    protected activeCartService: RrsActiveCartService
  ) {
    super(eventsService, windowRef, tmsConfig, injector);
    this.initCookies();
  }

  async collect(): Promise<void> {
    try {
      if (!this.windowRef.isBrowser()) {
        return;
      }

      const tmsConfiguration = this.getTmsConfig(
        environment.adobeLaunch?.scriptUrl
      );

      for (const tmsCollectorConfig in tmsConfiguration) {
        if (!tmsConfiguration?.hasOwnProperty(tmsCollectorConfig)) {
          continue;
        }

        const collectorConfig = tmsConfiguration[tmsCollectorConfig] ?? {};

        if (!collectorConfig.collector) {
          if (isDevMode()) {
            console.warn(
              `Skipping the '${tmsCollectorConfig}', as the collector is not defined.`
            );
          }
          continue;
        }

        const events =
          collectorConfig.events?.map((event) =>
            this.eventsService.get(event)
          ) || [];
        const collector = this.injector.get<RrsAepCollectorService>(
          collectorConfig.collector
        );
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await collector.init(collectorConfig, this.windowRef.nativeWindow!);

        this.subscription.add(
          this.mapEvents(events).subscribe((event) => {
            if (collectorConfig.debug) {
              console.log(
                `🎤 Pushing the following event to ${tmsCollectorConfig}: `,
                event
              );
            }

            collector.pushEvent(
              collectorConfig,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              this.windowRef.nativeWindow!,
              event
            );
          })
        );
      }
    } catch (e) {
      console.error(e);
    }
  }

  protected override mapEvents<T extends RrsEvent>(
    events: Observable<T>[]
  ): Observable<T> {
    return merge(...events).pipe(
      withLatestFrom(this.userIdService.getUserId()),
      // Pushing information common for all events
      map(([event, userId]) => {
        if (event instanceof RrsCustomEvent) {
          if (event.event?.length) {
            event.event = [
              ...event.event.map((event) => ({
                ...event,
                userInfo: getEventUserInfo(userId),
              })),
            ];
          }
        }
        return event;
      })
    );
  }

  private initCookies(): void {
    const loyaltyIdSub = this.userAccountService
      .get()
      .subscribe((user: User | undefined) => {
        if (user?.loyaltyId) {
          this.setCookie('Cust_ID', user.loyaltyId);
        }
      });

    const cartQtySub = this.activeCartService
      .getActive()
      .pipe(filter((cart) => !!cart.code))
      .subscribe((cart) => {
        if (cart.totalItems !== undefined) {
          this.setCookie('Cart_qty', String(cart.totalItems));
        }
      });

    this.subscription.add(loyaltyIdSub);
    this.subscription.add(cartQtySub);
  }

  private setCookie(name: string, value: string): void {
    if (this.windowRef.isBrowser()) {
      this.windowRef.nativeWindow?._satellite?.cookie?.set(name, value);
    }
  }

  private getTmsConfig(aepScriptUrl?: string): TmsCollectors {
    const config: TmsCollectors = {};

    if (aepScriptUrl?.length) {
      config.aep = {
        scriptUrl: aepScriptUrl,
        collector: RrsAepCollectorService,
        events: [
          RrsSearchPageEvent,
          RrsCategoryPageEvent,
          RrsGenericPageEvent,
          RrsCartPageEvent,
          RrsProductDetailsPageEvent,
          RrsCheckoutPageEvent,
          RrsMyProfilePageEvent,
          RrsAddToCartEvent,
          RrsRemoveFromCartEvent,
          RrsAddToWishlistEvent,
          RrsLiveChatClickEvent,
          RrsAddNewShippingAddressEvent,
          RrsEditShippingAddressEvent,
          RrsEditAccountInfoEvent,
          RrsSocialLinkClickEvent,
          RrsInternalSearchClickEvent,
          RrsExpressCheckoutEvent,
          RrsSortChangeEvent,
          RrsFacetChangeEvent,
          RrsFacetClickedEvent,
          RrsFacetViewedEvent,
          RrsOrderPlacedEvent,
          RrsErrorNotificationEvent,
          RrsEmailSignupSuccessEvent,
          RrsKlarnaPaymentEvent,
          RrsLoginSuccessEvent,
          RrsRegistrationStartEvent,
          RrsRegistrationSuccessEvent,
          RrsAddToCartSuccessEvent,
          RrsCartRemoveEntrySuccessEvent,
          RrsAutoCompleteProductClickedEvent,
        ],
        debug: !environment.production,
      };
    }

    return config;
  }
}
