import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BASE_SITE_NORMALIZER,
  BaseSite,
  ConverterService,
  OccEndpointsService,
  OccSiteAdapter,
  SiteAdapter,
} from '@spartacus/core';
import { basesitesConfig } from '@app/custom/features/rrs-basesite/configs/basesites.config';

@Injectable()
export class RrsOccSiteAdapter extends OccSiteAdapter implements SiteAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected converterService: ConverterService
  ) {
    super(http, occEndpointsService, converterService);
  }

  loadBaseSites(): Observable<BaseSite[]> {
    return of(basesitesConfig).pipe(
      map((baseSiteList) => baseSiteList.baseSites),
      this.converterService.pipeableMany(BASE_SITE_NORMALIZER)
    );
  }
}
