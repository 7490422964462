import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RrsActiveCartService } from '@app/custom/features/rrs-cart/services/rrs-active-cart.service';
import { RrsCheckoutService } from '@app/custom/features/rrs-checkout/rrs-checkout.service';
import { PayPalInitialData } from '@app/custom/features/rrs-pay-pal/paypal.model';
import { RrsScriptLoadService } from '@app/shared/services/rrs-script-load.service';
import { objectNotEmpty } from '@app/shared/utils';
import {
  isAnonymousCart,
  preparePaymentEndpointData,
} from '@app/shared/utils/cart.helper';
import { decorateRegionWithUSPrefix } from '@app/shared/utils/common';
import { AsmEnablerService } from '@spartacus/asm/root';
import { Cart } from '@spartacus/cart/base/root';
import {
  AuthService,
  EventService,
  GlobalMessageService,
  GlobalMessageType,
  OccEndpointsService,
  RouterState,
  RoutingService,
  normalizeHttpError,
} from '@spartacus/core';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { RrsEventsDispatcherService } from '../rrs-tms/rrs-adobe-experience/events/services/rrs-events.dispatcher';
import { CONTENTSTACK_CMS_PAGE_LIST } from '../rrs-cms/configs/contentstack.config';

const defaultBtnStyle: {
  color: string;
  height: number;
  label?: string;
  tagline: boolean;
  disableMaxWidth?: boolean;
} = {
  color: 'white',
  height: 42,
  tagline: false,
};

@Injectable({
  providedIn: 'root',
})
export class RrsPayPalService {
  private token: any;
  private sdkLoaded = false;
  private isPaymentInProgress$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    private scriptLoadService: RrsScriptLoadService,
    private activeCartService: RrsActiveCartService,
    private occEndpointsService: OccEndpointsService,
    private http: HttpClient,
    private authService: AuthService,
    protected checkoutService: RrsCheckoutService,
    protected asmEnablerService: AsmEnablerService,
    protected globalMessageService: GlobalMessageService,
    protected routingService: RoutingService
  ) {}

  loadSDK(): Observable<[boolean, boolean]> {
    if (this.sdkLoaded) {
      return of([true, true]);
    }
    return forkJoin([
      this.scriptLoadService.loadScript(
        `https://js.braintreegateway.com/web/3.91.0/js/paypal-checkout.min.js`
      ),
      this.scriptLoadService.loadScript(
        `https://js.braintreegateway.com/web/3.91.0/js/client.min.js`
      ),
    ]).pipe(tap(() => (this.sdkLoaded = true)));
  }

  setPaymentInProgressState(state: boolean): void {
    this.isPaymentInProgress$.next(state);
  }

  getPaymentInProgressState(): Observable<boolean> {
    return this.isPaymentInProgress$.asObservable();
  }

  initPaypal(
    doExpressCheckout: any,
    style = defaultBtnStyle
  ): Promise<void> | undefined {
    return new Promise((resolve, reject) => {
      this.loadSDK()
        .pipe(
          switchMap(() => combineLatest([this.getToken()])),
          catchError((error) => throwError(normalizeHttpError(error))),
          take(1)
        )
        .subscribe(
          ([token]: [string]) => {
            this.token = token;

            window.braintree.client
              .create({
                authorization: this.token,
              })
              .then((clientInstance: any) => {
                return window.braintree.paypalCheckout.create({
                  client: clientInstance,
                });
              })
              .then((paypalCheckoutInstance: any) => {
                return paypalCheckoutInstance.loadPayPalSDK({
                  currency: 'USD',
                  intent: 'authorize',
                });
              })
              .then((paypalCheckoutInstance: any) => {
                window.paypal
                  .Buttons({
                    fundingSource: window.paypal.FUNDING.PAYPAL,
                    style,
                    createOrder: async () => {
                      try {
                        const cart = await this.getCartPromise();
                        const amount = cart.totalPriceWithTax?.value ?? 0;
                        const deliveryAddress = cart.deliveryAddress ?? {};

                        return paypalCheckoutInstance.createPayment({
                          flow: 'checkout', // Required
                          amount, // Required
                          currency: 'USD', // Required, must match the currency passed in with loadPayPalSDK
                          requestBillingAgreement: true, // Required
                          billingAgreementDetails: {
                            description: 'Paypal Billing Agreement',
                          },
                          intent: 'authorize', // Must match the intent passed in with loadPayPalSDK
                          enableShippingAddress: true,
                          shippingAddressEditable: false,
                          shippingAddressOverride: objectNotEmpty(
                            deliveryAddress
                          )
                            ? {
                                recipientName: deliveryAddress?.firstName,
                                line1: deliveryAddress?.line1,
                                line2: deliveryAddress?.line2,
                                city: deliveryAddress?.town,
                                countryCode: deliveryAddress?.country?.isocode,
                                postalCode: deliveryAddress?.postalCode,
                                state: decorateRegionWithUSPrefix(
                                  deliveryAddress?.region?.isocode
                                ),
                                phone: deliveryAddress?.phone,
                              }
                            : {},
                        });
                      } catch (error) {
                        this.globalMessageService.add(
                          'Error fetching cart details',
                          GlobalMessageType.MSG_TYPE_ERROR
                        );
                        console.error('Error fetching cart details:', error);
                      }
                    },
                    onApprove: async (data: any, actions: any) => {
                      try {
                        this.setPaymentInProgressState(true);
                        const cart = await this.getCartPromise();
                        return paypalCheckoutInstance
                          .tokenizePayment(data)
                          .then((payload: any) => {
                            this.sendNonce(payload, cart)
                              .pipe(take(1))
                              .subscribe(
                                () => {
                                  this.activeCartService.reloadActiveCart();
                                  this.routingService
                                    .getRouterState()
                                    .pipe(take(1))
                                    .subscribe((routerState: RouterState) => {
                                      if (
                                        routerState.state.context.id ===
                                        CONTENTSTACK_CMS_PAGE_LIST.CART
                                      ) {
                                        if (
                                          typeof doExpressCheckout !==
                                          'function'
                                        ) {
                                          console.error(
                                            'doExpressCheckout is not a function'
                                          );
                                          return;
                                        }
                                        doExpressCheckout(cart, payload);
                                      }
                                    });
                                },
                                (error) => {
                                  this.globalMessageService.add(
                                    `Error setting PayPal payment info: ${error}`,
                                    GlobalMessageType.MSG_TYPE_ERROR
                                  );
                                  console.error(error);
                                },
                                () => {
                                  this.setPaymentInProgressState(false);
                                }
                              );
                          });
                      } catch (error) {
                        this.setPaymentInProgressState(false);
                        this.globalMessageService.add(
                          'Error fetching cart details',
                          GlobalMessageType.MSG_TYPE_ERROR
                        );
                        console.error('Error fetching cart details:', error);
                      }
                    },
                    onCancel: function (data: any) {},
                    onError: (err: any) => {
                      // TODO: Re-implement customer-facing error handling after Angular SPA compatibility issue has been fixed.
                      console.error('PayPal Buttons Error:', err);
                    },
                  })
                  .render('#paypal-button');
              })
              .catch((error: any) => {
                // TODO: Re-implement customer-facing error handling after Angular SPA compatibility issue has been fixed.
                console.error('Braintree PayPal Error:', error?.message);
              });

            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getToken(): Observable<string> {
    if (this.token) {
      return of(this.token);
    }
    return combineLatest([
      this.activeCartService.takeActive(),
      this.authService.isUserLoggedIn(),
    ]).pipe(
      take(1),
      switchMap(([cart, userLogged]: [Cart, boolean]) => {
        let { cartId, userId, headers } = preparePaymentEndpointData(
          cart,
          userLogged,
          this.asmEnablerService.isEnabled()
        );

        const endpoint = this.occEndpointsService.buildUrl(
          `users/${userId}/carts/${cartId}/braintree/paypalinitialdata`
        );
        return this.http.post<PayPalInitialData>(endpoint, null, { headers });
      }),
      catchError((error) => throwError(normalizeHttpError(error))),
      map((resp: any) => {
        return resp?.token;
      })
    );
  }

  getCartPromise(): Promise<Cart> {
    return this.activeCartService.takeActive().pipe(take(1)).toPromise();
  }

  addEmailIfAnonymous(cart: Cart, email: string): Observable<boolean> {
    if (isAnonymousCart(cart.user)) {
      this.activeCartService.addEmail(email);
      return this.activeCartService.isGuestCart().pipe(
        filter(Boolean),
        take(1),
        map(() => true)
      );
    } else {
      return of(false);
    }
  }

  sendNonce(paymentDetails: any, cart: Cart): Observable<any> {
    return combineLatest([
      this.activeCartService.getActive(),
      this.authService.isUserLoggedIn(),
      this.addEmailIfAnonymous(cart, paymentDetails.details.email),
    ]).pipe(
      take(1),
      switchMap(([cart, userLogged]: [Cart, boolean, boolean]) => {
        let { cartId, userId, headers } = preparePaymentEndpointData(
          cart,
          userLogged,
          this.asmEnablerService.isEnabled()
        );
        const endpoint = this.occEndpointsService.buildUrl(
          `users/${userId}/carts/${cartId}/paymentdetails/braintree`
        );
        return this.http.post(endpoint, paymentDetails, { headers });
      }),
      catchError((error) => throwError(normalizeHttpError(error)))
    );
  }
}
