import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import {
  localMessagesCheckoutConfig,
  localMessagesConfig,
} from '@app/custom/features/rrs-global-message/configs';
import {
  RrsLocalMessageConfig,
  RrsLocalMessageScope,
} from '@app/custom/features/rrs-global-message/model';
import { RrsShopLookService } from '@app/custom/features/rrs-shop-look/services/rrs-shop-look.service';
import { RrsEventsDispatcherService } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/services/rrs-events.dispatcher';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { Store } from '@ngrx/store';
import {
  GlobalMessageActions,
  GlobalMessageEntities,
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
  StateWithGlobalMessage,
  TranslationService,
} from '@spartacus/core';
import { Translatable } from '@spartacus/core/src/i18n/translatable';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@AutoUnsubscribe()
@Injectable({
  providedIn: 'root',
})
export class RrsGlobalMessageService extends GlobalMessageService {
  constructor(
    private routingService: RoutingService,
    store: Store<StateWithGlobalMessage>,
    protected router: Router,
    protected eventsDispatcher: RrsEventsDispatcherService,
    protected translateService: TranslationService,
    protected rrsShopLookService: RrsShopLookService
  ) {
    super(store);
    this.subscriptions.add(
      router.events
        .pipe(filter((event: Event) => event instanceof NavigationEnd))
        .subscribe(() => {
          if (
            this.router.getCurrentNavigation()?.extras?.state
              ?.preserveGlobalMessage
          )
            return;
          this.clearAllMessages();
        })
    );
  }

  subscriptions = new Subscription();

  add(
    text: string | Translatable,
    type: GlobalMessageType,
    timeout?: number
  ): void {
    this.store.dispatch(
      new GlobalMessageActions.AddMessage({
        text: typeof text === 'string' ? { raw: text } : text,
        type,
        timeout,
      })
    );
    if (type === GlobalMessageType.MSG_TYPE_ERROR) {
      this.getMessageContent(text)
        .pipe(take(1))
        .subscribe((message) => {
          this.eventsDispatcher.dispatchErrorNotificationEvent(message);
        });
    }
  }

  get(scopeName?: string): Observable<GlobalMessageEntities> {
    return combineLatest([
      super.get(),
      this.routingService.getRouterState(),
    ]).pipe(
      map(([messages, routerState]) => {
        const messagesTypeKeys = Object.keys(messages);
        const filteredMessages: GlobalMessageEntities = {};
        messagesTypeKeys.forEach((messagesTypeKey) => {
          let messagesGroupByType = JSON.parse(
            JSON.stringify(messages[messagesTypeKey])
          ) as Translatable[];
          messagesGroupByType = messagesGroupByType.filter((message) => {
            const messageScope = this.getMessageScope(
              routerState,
              message.key || message.raw
            );
            const sameScope = scopeName === messageScope?.name;
            if (this.rrsShopLookService.shopLookOpen && scopeName) {
              return true;
            }
            return (
              !this.rrsShopLookService.shopLookOpen &&
              ((messageScope && sameScope) ||
                (messageScope && !sameScope && !messageScope?.ignoreGlobally) ||
                (!messageScope && sameScope))
            );
          });
          if (messagesGroupByType.length > 0) {
            filteredMessages[messagesTypeKey] = messagesGroupByType;
          }
        });
        return filteredMessages;
      })
    );
  }

  getMessageScope(
    routerState: any,
    message?: string
  ): RrsLocalMessageScope | undefined {
    if (routerState.state.url.includes('checkout')) {
      return this.findScope(localMessagesCheckoutConfig, message);
    }
    return this.findScope(localMessagesConfig, message);
  }

  remove(type: GlobalMessageType, index?: number): void {
    super.remove(type, index);
  }

  findScope(
    config: RrsLocalMessageConfig,
    message?: string
  ): RrsLocalMessageScope | undefined {
    if (!message) {
      return undefined;
    }
    return config.scopes.find((scope) =>
      scope.globalMessagesKeys.includes(message)
    );
  }

  clearAllMessages(): void {
    this.get()
      .pipe(take(1))
      .subscribe((messages) => {
        Object.values(GlobalMessageType).forEach((messageType) => {
          const messagesFromCategory = messages[messageType];
          if (!messagesFromCategory || !messagesFromCategory.length) return;
          messages[messageType].forEach(() => {
            this.remove(messageType);
          });
        });
      });
  }

  private getMessageContent(text: string | Translatable): Observable<string> {
    if (this.isTranslatable(text)) {
      if (text.raw) {
        return of(text.raw);
      } else if (text.key) {
        return this.translateService
          .translate(text.key, text.params)
          .pipe(take(1));
      }
    }

    return of(text as string);
  }

  private isTranslatable(text: string | Translatable): text is Translatable {
    return Boolean((text as Translatable).key || (text as Translatable).raw);
  }
}
