import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SiteAdapter } from '@spartacus/core';
import { RrsOccSiteAdapter } from '@app/custom/features/rrs-basesite/adapters/rrs-occ-site-adapter';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: SiteAdapter,
      useClass: RrsOccSiteAdapter,
    },
  ],
})
export class RrsBasesiteModule {}
