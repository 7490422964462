<div class="modal-header py-3">
  <button
    *ngIf="productCode && productCodes.length > 1"
    class="modal-title h4"
    (click)="openPLP()">
    <rrs-icon [icon]="iconTypeList.CHEVRON_LEFT" [size]="12"></rrs-icon> Back
  </button>
  <h4
    class="modal-title"
    *ngIf="!productCode || productCodes.length === 1"
    class="modal-title"
    id="modal-basic-title">
    {{ title }}
  </h4>
  <button
    [attr.aria-label]="'rrs.common.closeModal' | cxTranslate"
    class="btn-close"
    cxModal="dismiss"
    cxModalReason="Cross click"
    type="button"></button>
</div>

<div class="modal-body">
  <rrs-global-message
    class="fixed-message"
    [scopeName]="'shopLook'"></rrs-global-message>
  <ng-container
    *ngIf="productCode && product$ | async as product; else productList">
    <!-- Start of Image Gallary -->
    <ng-container *ngIf="productGallery$ | async as productGallery">
      <swiper
        #swiper
        [config]="configMain"
        class="mb-2 pb-5"
        (slideChange)="onSlideChange()">
        <!-- Slides -->
        <ng-template
          swiperSlide
          *ngFor="let item of productGallery"
          (click)="zoomInMobile()">
          <rrs-media
            (click)="openModal()"
            [container]="item.container | rrsProductImages : scene7Preset"
            height="416"
            width="624"
            format="product"
            class="swiper-zoom-container"
            tabindex="0"
            (mouseenter)="zoomIn()"
            (mouseleave)="zoomOut()">
          </rrs-media>
          <rrs-icon
            [icon]="iconTypeList.SEARCH_PLUS"
            [size]="48"
            class="position-absolute bottom-0 end-0 shop-look-pdp-zoom-icon"></rrs-icon>
        </ng-template>
      </swiper>
    </ng-container>

    <!-- End of Image Gallary -->

    <!-- Start of Product Category -->
    <ng-container *ngIf="brandCategory$ | async as brandCategory">
      <a
        class="link-primary text-uppercase mb-2"
        [routerLink]="
          { cxRoute: 'search', params: { query: brandCategory.name } } | cxUrl
        ">
        {{ brandCategory.name }}
      </a>
    </ng-container>

    <!-- End of Product Category-->
    <!-- Start Product Summary -->
    <ng-container>
      <h1 *ngIf="product?.name" data-cy="product-title" class="fw-normal fs-3">
        {{ product.name }}
      </h1>
      <div class="d-flex flex-wrap">
        <a *ngFor="let badge of badges" class="d-flex text-decoration-none">
          <span
            [style.background-color]="badge.bgColor"
            [style.color]="badge.textColor"
            [style.border]="badge.border"
            class="badge"
            >{{ badge.label }}</span
          >
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="!isDonation">
      <div class="row row-cols-1">
        <div *ngIf="product.price?.isVisible === true; else priceHidden">
          <p class="text-success fw-bold fs-6" data-cy="product-price">
            <span *ngIf="product.price?.comparePrice">SALE</span>
            {{ product.price?.formattedValue }}
            <span
              *ngIf="product.price?.comparePrice"
              class="fs-6 fw-normal text-light text-decoration-line-through ms-1">
              ${{ product.price?.comparePrice }}</span
            >
          </p>
          <div class="d-flex flex-wrap mb-4">
            <a
              *ngFor="let badge of bogoBadges"
              class="d-flex"
              [href]="badge.categoryUrl"
              target="_blank">
              <span [class]="badge.class" [innerHTML]="badge.label"></span>
            </a>
          </div>
        </div>
        <ng-template #priceHidden>
          <p>
            {{ 'rrs.product.SeePriceInCart' | cxTranslate }}
          </p>
        </ng-template>
      </div>
    </ng-container>
    <!-- End Product Summary-->
    <rrs-product-variants-container
      [productCode]="product.code"></rrs-product-variants-container>
    <cx-page-slot position="need_help_dropdown"></cx-page-slot>
    <a
      cxModal="dismiss"
      cxModalReason="Cross click"
      [routerLink]="[product.url]"
      class="py-3 text-center fw-bold fs-5 text-decoration-underline justify-content-center d-flex"
      >View full product page</a
    >
  </ng-container>
  <ng-template #productList>
    <ng-container
      *ngIf="productCodes?.length && model$ | async as result; else loader">
      <div class="col-12">
        <div class="row">
          <rrs-product-card
            [isShopLook]="true"
            *ngFor="let product of result.products"
            [product]="product"
            class="col-6"
            data-cy="productCard">
          </rrs-product-card>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
<ng-container *ngIf="isLoading$ | async; then loader"></ng-container>
<ng-template #loader>
  <div
    class="modal-body d-flex align-items-center justify-content-center position-fixed top-0 start-0 vh-100 w-100 bg-white z-2000 opacity-75">
    <div class="spinner-border spinner-size" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
