<ng-container *ngIf="product$ | async as product">
  <ng-container *ngIf="product && product.code !== donationProductType">
    <rrs-product-variants-colors
      [product]="product"></rrs-product-variants-colors>
    <section>
      <ng-container *ngIf="!shouldShowSizeSelector">
        <h6 class="mb-2">
          {{ 'rrs.product.variants.sizes' | cxTranslate }}:
          <span class="fw-normal">{{ form.get('sizes').value }}</span>
        </h6>

        <div class="row g-2 mb-4">
          <div
            *ngFor="let item of sizes | keyvalue : originalOrder"
            class="col-auto"
            [ngClass]="{
              'col-6 col-xl-4': item.value[0]?.sizeconversion
            }">
            <button
              (click)="setSizes(item.key)"
              [disabled]="
                form.get('width')?.value === '' ||
                (form.get('width')?.value && hasWidth(item.key))
              "
              [ngClass]="{
                'btn-size--active': item.key === form.get('sizes').value,
                'btn-size--wide': item.value[0]?.sizeconversion
              }"
              class="btn btn-secondary btn-size">
              {{ item.value[0]?.sizeconversion || item.key }}
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="width.size > 1">
        <h6 class="mb-2">
          {{ 'rrs.product.variants.width' | cxTranslate }}:
          <span class="fw-normal">{{ form.get('width').value }}</span>
        </h6>

        <div class="btn-group mb-4">
          <button
            (click)="setWidth(item.key)"
            *ngFor="let item of width | keyvalue : originalOrder"
            [disabled]="form.get('sizes')?.value && hasSizes(item.key)"
            [ngClass]="{
              'btn-size--active': item.key === form.get('width').value
            }"
            class="btn btn-secondary btn-size">
            {{ item.key }}
          </button>
        </div>
      </ng-container>
    </section>

    <rrs-delivery-options
      class="row mb-2"
      (selectFulfillment)="selectFulfillment($event)"
      (store)="setStoreInfo($event)"
      [fulfillment]="fulfillmentConfig"
      [productCode]="getProductCode ?? product.code"
      [productPrice]="product.price">
    </rrs-delivery-options>
    <ng-container *ngIf="storeInfo">
      <div class="d-flex align-items-center mb-3">
        <rrs-store-logo
          class="d-block"
          [store]="storeInfo"
          [size]="15"></rrs-store-logo>
        <p class="fw-bold ms-2 mb-0">
          {{ storeInfo.displayName | titlecase }}
        </p>
        <button
          (click)="openFindStoresDialog()"
          class="btn btn-link link-primary fw-normal p-0 ms-1 align-items-start text-capitalize">
          {{ 'rrs.product.deliveryOptions.change' | cxTranslate }}
        </button>
      </div>
    </ng-container>
    <div class="mb-3">
      <rrs-add-to-cart
        [addToCartStatus]="getAddToCartStatus()"
        [deliveryModeName]="fulfillmentConfig.selectedMethod"
        [productCode]="getProductCode"
        [product]="product"
        [showQuantity]="false"
        (openStorePopup)="openFindStoresDialog($event)">
      </rrs-add-to-cart>
    </div>

    <div class="d-flex justify-content-center">
      <rrs-add-to-wishlist
        [disabled]="
          product.variantOptions?.length
            ? !(form.value.sizes && form.value.width)
            : false
        "
        [newDesign]="true"
        [productCode]="getProductCode"
        [product]="product">
      </rrs-add-to-wishlist>
    </div>
    <div class="pickup__message d-flex p-2">
      <span class="text-accent">*&nbsp;</span>
      <p>
        <ng-container *ngIf="storeInfo"
          >{{
            (storeInfo.curbsidePickupEnabled
              ? 'rrs.product.deliveryOptions.bopuisAndCurbsideEligible'
              : 'rrs.product.deliveryOptions.bopuisEligible'
            ) | cxTranslate
          }}
        </ng-container>
        {{
          (storeInfo
            ? 'rrs.product.deliveryOptions.pickupInStoreMsg'
            : 'rrs.product.deliveryOptions.hoursVaryByLocation'
          ) | cxTranslate
        }}
      </p>
    </div>
  </ng-container>
</ng-container>
