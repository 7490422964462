import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { RrsToastService } from '@app/custom/features/rrs-toast/services/rrs-toast.service';

@Component({
  selector: 'rrs-toast',
  templateUrl: './rrs-toast.component.html',
  styleUrls: ['./rrs-toast.component.scss'],
  animations: [
    trigger('toastTrigger', [
      // This refers to the @trigger we created in the template
      state('open', style({ opacity: '1' })), // This is how the 'open' state is styled
      state('close', style({ opacity: '0' })), // This is how the 'close' state is styled
      transition('open <=> close', [
        // This is how they're expected to transition from one to the other
        animate('300ms ease-in-out'),
      ]),
    ]),
  ],
})
export class RrsToastComponent {
  toastMessage = 'This is a toast';
  showsToast = false;
  constructor(public service: RrsToastService) {}

  onClick(): void {
    this.service.dismissToast();
  }
}
