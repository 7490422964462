<ng-container *ngIf="isUpdating$ | async"></ng-container>

<header class="modal-header d-flex align-items-center justify-content-between">
  <h4 class="mb-0">
    {{ 'rrs.account.personalInformation' | cxTranslate }}
  </h4>

  <button
    (click)="close()"
    aria-label="Close"
    class="btn btn-close"
    type="button"></button>
</header>
<rrs-global-message></rrs-global-message>
<form
  #ngForm="ngForm"
  (ngSubmit)="onSubmit()"
  [formGroup]="form"
  class="modal-body">
  <div class="mb-3">
    <label class="form-label" for="firstName">
      {{ 'rrs.account.form.firstName' | cxTranslate }}
    </label>

    <input
      [ngClass]="
        ngForm.submitted ? (form.get('firstName') | rrsIsControlValid) : ''
      "
      aria-required="true"
      class="form-control"
      formControlName="firstName"
      name="firstName"
      type="text" />

    <rrs-form-errors *ngIf="ngForm.submitted" [control]="form.get('firstName')">
    </rrs-form-errors>
  </div>

  <div class="mb-3">
    <label class="form-label" for="firstName">
      {{ 'rrs.account.form.lastName' | cxTranslate }}
    </label>

    <input
      [ngClass]="
        ngForm.submitted ? (form.get('lastName') | rrsIsControlValid) : ''
      "
      aria-required="true"
      class="form-control"
      formControlName="lastName"
      name="lastName"
      type="text" />

    <rrs-form-errors *ngIf="ngForm.submitted" [control]="form.get('lastName')">
    </rrs-form-errors>
  </div>

  <div class="mb-3">
    <label class="form-label" for="customerPhone">
      {{ 'rrs.account.form.customerPhone' | cxTranslate }}
    </label>

    <input
      [ngClass]="
        ngForm.submitted ? (form.get('customerPhone') | rrsIsControlValid) : ''
      "
      aria-required="true"
      class="form-control"
      formControlName="customerPhone"
      name="customerPhone"
      placeholder="(555) 555-5555"
      rrsPhoneNumberInput
      type="tel" />

    <rrs-form-errors
      *ngIf="ngForm.submitted"
      [control]="form.get('customerPhone')"></rrs-form-errors>
  </div>

  <div class="mb-3">
    <label class="form-label" for="monthOfBirth">
      {{ 'rrs.account.form.monthOfBirth' | cxTranslate }}
    </label>

    <ng-select
      [clearable]="false"
      [items]="monthOfBirthOptions"
      bindValue="value"
      formControlName="monthOfBirth"
      labelForId="monthOfBirth"
      placeholder="Select Month">
    </ng-select>

    <rrs-form-errors
      *ngIf="ngForm.submitted"
      [control]="form.get('monthOfBirth')"></rrs-form-errors>
  </div>

  <div class="mb-3">
    <label class="form-label" for="gender">
      {{ 'rrs.account.form.gender' | cxTranslate }}
    </label>

    <ng-select
      [clearable]="false"
      [items]="genderOptions"
      bindValue="value"
      formControlName="gender"
      labelForId="gender"
      placeholder="Select Gender">
    </ng-select>

    <rrs-form-errors
      *ngIf="ngForm.submitted"
      [control]="form.get('gender')"></rrs-form-errors>
  </div>

  <div class="d-flex gap-3">
    <button
      (click)="close()"
      class="btn btn-block btn-secondary"
      ngbAutofocus
      type="button">
      {{ 'common.cancel' | cxTranslate }}
    </button>

    <button [disabled]="form.disabled" class="btn btn-block btn-primary">
      {{ 'common.save' | cxTranslate }}
    </button>
  </div>
</form>
