import { defaultOccUserConfig } from '@app/spartacus/configurations/occ/config';
import { environment } from '@environments/environment';
import {
  PWAModuleConfig,
  defaultCmsContentProviders,
} from '@spartacus/storefront';
import {
  FeaturesConfig,
  I18nConfig,
  OccConfig,
  provideConfig,
  provideDefaultConfig,
  SiteContextConfig,
  RoutingConfig,
  StateConfig,
  RouteLoadStrategy,
  StateTransferType,
  Price,
} from '@spartacus/core';
import { CardRewards } from '@app/custom/features/rrs-rewards/models/rewards.model';
import { GENDER } from '@app/shared/models';
import { NgModule } from '@angular/core';
import { rrsIconConfig } from '@app/spartacus/configurations/icon/icon.config';
import { rrsLayoutConfig } from '@app/spartacus/configurations/layout/layout.config';
import { RrsPayment } from '@app/custom/features/rrs-checkout/model';
import { translationChunksConfig } from '@spartacus/assets';
import { PdpAvailability } from '@app/custom/features/rrs-product-details/models/rrs-product.model';
import { mediaConfig } from '@app/spartacus/configurations/media/media.config';
import { routingConfig } from '@app/spartacus/configurations/routing/routing.config';

declare module '@spartacus/core' {
  interface Product {
    configurable?: boolean;
    oldPrice?: Price;
    siblings?: Product[];
    pdpAvailability?: PdpAvailability;
    brand?: string;
    colorPrimary?: string;
    colorSecondary?: string;
    size?: string;
    width?: string;
    isProductWithoutSize?: boolean;
    objectID?: string;
    queryID?: string;
  }

  interface VariantOption {
    size: string;
    width: string;
    colorPrimary?: string;
    colorSecondary?: string;
    sizeconversion?: string;
    pdpAvailability?: PdpAvailability;
    vendorUPC?: string;
  }

  interface Address {
    nickname?: string;
  }

  interface User {
    monthOfBirth?: string;
    email?: string;
    gender?: GENDER;
    customerPhone?: string;
    loyaltyId?: string;
  }

  interface Page {
    canonicalUrl?: string;
    schema?: string;
    heading?: string;
    endOfContent?: string;
  }

  interface PaymentDetails {
    tenderType: string;
    defaultPaymentInfo: boolean;
  }

  interface Price {
    comparePrice?: number;
    isVisible?: boolean;
  }
}

declare module '@spartacus/cart/base/root' {
  interface Cart {
    paymentTenderDataList?: RrsPayment[];
    rewards?: CardRewards[];
    autoPromotionCodes?: string;
    autoPromotionDiscount?: number;
    totalBasePrice?: Price;
  }
}

declare module '@spartacus/order/root' {
  interface Order {
    paymentTenderDataList?: RrsPayment[];
    rewards?: CardRewards[];
    autoPromotionCodes?: string;
    autoPromotionDiscount?: number;
    totalBasePrice?: Price;
  }
}

declare global {
  interface Window {
    pwr: any;
  }
}

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    provideDefaultConfig(defaultOccUserConfig),
    provideConfig(rrsLayoutConfig),
    provideConfig(mediaConfig),
    ...defaultCmsContentProviders,
    provideConfig({
      backend: {
        occ: {
          baseUrl: environment.occBaseUrl,
        },
      },
    } as OccConfig),
    provideConfig({
      pwa: {
        enabled: true,
      },
    } as PWAModuleConfig),
    provideConfig({
      context: {
        baseSite: ['rrsBasesite'],
        currency: ['USD'],
        language: ['en'],
        urlParameters: [],
      },
    } as SiteContextConfig),
    provideConfig({
      i18n: {
        backend: {
          loadPath: environment.i18n.backend.loadPath,
        },
        chunks: translationChunksConfig,
        fallbackLang: 'en',
      },
    } as I18nConfig),
    provideConfig({
      features: {
        level: '4.3',
      },
    } as FeaturesConfig),
    provideConfig(rrsIconConfig),
    provideConfig(routingConfig),
    provideConfig({
      state: {
        ssrTransfer: {
          keys: {
            cms: StateTransferType.TRANSFER_STATE,
            siteContext: StateTransferType.TRANSFER_STATE,
          },
        },
      },
    } as StateConfig),
  ],
})
export class SpartacusConfigurationModule {}
