import { isPlatformBrowser, Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DONATION_PRODUCT_TYPE } from '@app/custom/features/rrs-donation/models/donation.constant';
import { RrsKlarnaBannerService } from '@app/custom/features/rrs-klarna-banner/rrs-klarna-banner.service';
import { RrsPowerReviewsService } from '@app/custom/features/rrs-power-reviews/services/rrs-power-reviews.service';
import { Badge } from '@app/shared/configs';
import { getBadges, includeBogoBadgesIfApplicable } from '@app/shared/utils';
import { RrsProductScope } from '@app/spartacus/configurations/types.config';
import {
  CmsService,
  Product,
  ProductScope,
  ProductService,
  RoutingService,
  ScriptLoader,
  WindowRef,
} from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
  KLARNA_CLIENT_ID,
  KLARNA_SCRIPT_SRC,
} from '@app/custom/features/rrs-klarna-banner/rrs-klarna-banner.config';

@Component({
  selector: 'rrs-product-details-container',
  styleUrls: ['./rrs-product-details-container.component.scss'],
  templateUrl: './rrs-product-details-container.component.html',
})
export class RrsProductDetailsContainerComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  protected subscription = new Subscription();

  @ViewChildren('klarnaBannerContainer')
  klarnaBannerContainer!: QueryList<ElementRef>;

  badges: Array<Badge> = [];
  bogoBadges: Array<Badge> = [];
  donationProductType = DONATION_PRODUCT_TYPE;
  isDonation = false;
  isLoading$!: Observable<boolean>;
  productCode$!: Observable<string>;
  product$: Observable<Product | null> = this.currentProductService
    .getProduct([RrsProductScope.ALL])
    .pipe(
      tap((product) => {
        this.isDonation =
          product?.baseProduct === this.donationProductType ||
          product?.code === this.donationProductType;
        if (product?.baseProduct === this.donationProductType) {
          this.routingService.go(['p/donation']);
        }
        if (isPlatformBrowser(this.platformId)) {
          this.setCorrectUrl(product);
        }
        this.badges = getBadges(product?.categories || []);
        this.bogoBadges = includeBogoBadgesIfApplicable(
          product?.categories || []
        );
      })
    );
  seoHeading$ = this.cmsService
    .getCurrentPage()
    .pipe(map((page) => page?.heading));

  @ViewChild('powerreviews')
  set displayReview(value: ElementRef) {
    if (!value) return;

    this.subscription.add(
      combineLatest([
        this.product$,
        this.activatedRoute.url.pipe(map((segments) => segments.join('/'))),
      ])
        .pipe(
          tap(async ([product, path]) => {
            this.rrsPowerReviewsService.loadPowerReviews(product, path);
          })
        )
        .subscribe()
    );
  }

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected currentProductService: CurrentProductService,
    protected routingService: RoutingService,
    protected rrsPowerReviewsService: RrsPowerReviewsService,
    protected rrsKlarnaBannerService: RrsKlarnaBannerService,
    protected cmsService: CmsService,
    protected productService: ProductService,
    protected router: Router,
    protected location: Location,
    protected scriptLoader: ScriptLoader,
    protected winRef: WindowRef,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  ngOnInit(): void {
    this.productCode$ = this.routingService
      .getRouterState()
      .pipe(map((state) => state.state.params['productCode']));

    this.subscription.add(
      this.productCode$
        .pipe(
          switchMap((code) =>
            this.productService.hasError(code, ProductScope.DETAILS)
          ),
          filter(Boolean),
          take(1)
        )
        .subscribe((hasError) => {
          if (hasError) {
            this.routingService.go(
              { cxRoute: 'notFound' },
              {
                skipLocationChange: true,
                state: { preserveGlobalMessage: true },
              }
            );
          }
        })
    );
    this.isLoading$ = this.productCode$.pipe(
      switchMap((code) =>
        combineLatest([
          this.productService.isLoading(code, RrsProductScope.ALL),
        ]).pipe(map((loadingStates) => loadingStates.some(Boolean)))
      )
    );
  }

  private createKlarnaBanner(price?: number): void {
    if (!this.klarnaBannerContainer?.first) {
      return;
    }

    const klarnaBanner = this.winRef.document.createElement('klarna-placement');
    klarnaBanner.className = 'd-block mb-3';
    klarnaBanner.dataset.key = 'credit-promotion-badge';
    klarnaBanner.dataset.locale = 'en-US';
    klarnaBanner.dataset.purchaseAmount = price?.toString().replace('.', '');

    this.klarnaBannerContainer.first.nativeElement.innerHTML = '';
    this.klarnaBannerContainer.first.nativeElement.appendChild(klarnaBanner);
  }

  ngAfterViewInit(): void {
    if (this.winRef.isBrowser()) {
      if (this.klarnaBannerContainer?.first) {
        this.subscription.add(
          this.product$
            .pipe(filter((product) => !!product?.price?.value))
            .subscribe((product) => {
              this.createKlarnaBanner(product?.price?.value);
            })
        );
      }

      this.subscription.add(
        this.klarnaBannerContainer.changes
          .pipe(
            switchMap(() =>
              this.product$.pipe(filter((product) => !!product?.price?.value))
            )
          )
          .subscribe((product) => {
            this.createKlarnaBanner(product?.price?.value);
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setCorrectUrl(product: Product | null): void {
    if (product?.url && location.href !== product.url) {
      this.location.replaceState(product.url, location.search);
    }
  }
}
