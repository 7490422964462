import {
  AVAILABLE_BADGES,
  Badge,
  BOGO_BADGE,
  MAX_BADGES_DISPLAYED,
} from '@app/shared/configs';
import { Category } from '@spartacus/core';

export function getBadges(categories: Category[]): Array<Badge> {
  const badges = AVAILABLE_BADGES.filter((badge) => {
    const badgeCategoryExists = categories.some(
      (category) => category.code === badge.categoryId
    );
    return badge.excludeCategory ? !badgeCategoryExists : badgeCategoryExists;
  });
  return badges
    .sort((a, b) => {
      return a.priority - b.priority;
    })
    .slice(0, MAX_BADGES_DISPLAYED);
}

export function includeBogoBadgesIfApplicable(
  categories: Category[]
): Array<Badge> {
  const badgeCategoryExists = categories.some(
    (category) => category.code === 'promotionseligible'
  );
  if (!badgeCategoryExists) {
    return [BOGO_BADGE];
  }
  return [];
}
