<ng-container *ngIf="store$ | async as store">
  <ng-container *ngIf="store?.name; else pageLoader">
    <div class="container my-3">
      <div>
        <a
          class="link-primary text-uppercase fs-7"
          [routerLink]="{ cxRoute: 'storeFinder' } | cxUrl"
          >{{ 'rrs.storeDetails.backToStoreFinder' | cxTranslate }}</a
        >
      </div>
      <h1 data-cy="store-details-title" class="fw-normal mt-3 fs-2">
        {{ (seoHeading$ | async) || store.displayName }}
        <br />
        {{
          (store.isOffBroadway
            ? 'rrs.storeDetails.offBroadwayWarehouse'
            : 'rrs.storeDetails.rrsWarehouse'
          ) | cxTranslate
        }}
      </h1>
      <div class="d-flex justify-content-between">
        <p class="me-3">
          {{ store.address?.line1 }} <br />
          {{ store.address?.town }}, {{ store.address?.region?.isocodeShort }}
          {{ store.address?.postalCode }} <br />
          <span class="store__phone"
            >{{ store.phoneNumber | rrsPhoneNumber }} </span
          ><br />
          <span
            *ngIf="(store | storeOpeningSchedule)?.[0] as todaySchedule"
            class="store__opening d-flex align-items-center mt-3">
            <ng-container
              [ngTemplateOutlet]="pulse"
              [ngTemplateOutletContext]="{
                isOpened: todaySchedule.isOpened
              }"></ng-container>
            <span class="text-uppercase">
              {{
                (todaySchedule.isOpened
                  ? 'rrs.storeFinder.weAreOpenToday'
                  : 'rrs.storeFinder.weAreClosedToday'
                ) | cxTranslate
              }}
              {{
                todaySchedule.isOpened
                  ? ': ' + todaySchedule.formattedOpeningHours
                  : ''
              }}
            </span>
          </span>
        </p>
        <div class="d-flex flex-column align-items-end">
          <div class="mb-3" *ngIf="isLoggedIn$ | async">
            <div
              data-cy="store-finder-make-my-store"
              class="form-check form-switch ps-0 flex-column flex-sm-row">
              <label
                class="form-check-label text-center mb-2 mb-sm-0"
                for="makeMyStoreSwitch">
                <h6 class="fs-7 m-0 fw-semibold">
                  {{ 'rrs.storeFinder.makeMyStore' | cxTranslate }}
                </h6>
              </label>
              <input
                class="form-check-input m-0 mx-3"
                id="makeMyStoreSwitch"
                type="checkbox"
                role="switch"
                [checked]="(defaultStoreId$ | async) === store.name"
                (change)="setAsDefaultStore(store.name, $event)" />
            </div>
          </div>
          <div>
            <ng-container *ngIf="displayOBBigLogo$ | async; else storeLogo">
              <img
                src="/assets/images/offBroadwayBig.svg"
                [attr.alt]="'common.images.offBroadwayLogoAlt' | cxTranslate"
                width="200" />
            </ng-container>
            <ng-template #storeLogo>
              <rrs-store-logo
                [store]="store"
                [size]="(isDesktop$ | async) ? 100 : 70"></rrs-store-logo>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <rrs-store-finder-map [location]="store"></rrs-store-finder-map>
    <div class="container py-3">
      <div class="row">
        <div class="col-lg-6 order-lg-1">
          <div class="row store__open-hours-container bg-white p-lg-3 fs-6">
            <div class="d-none d-lg-block col-lg-5">
              <rrs-media
                *ngIf="store.storeImages?.[0]; else logoFallback"
                width="238"
                height="206"
                [fit]="'constrain,0'"
                [container]="store.storeImages[0]"></rrs-media>
              <ng-template #logoFallback>
                <rrs-store-logo
                  class="d-flex justify-content-center"
                  [store]="store"
                  [size]="150"></rrs-store-logo>
              </ng-template>
            </div>
            <div class="store__open-hours col-lg-7">
              <h4>{{ 'rrs.storeFinder.hoursOfOperation' | cxTranslate }}</h4>

              <div
                *ngFor="
                  let schedule of store | storeOpeningSchedule;
                  let i = index
                "
                class="d-flex justify-content-between">
                <span *ngIf="i !== 0; else today"
                  >{{ schedule.formattedDate }}:</span
                >
                <ng-template #today>
                  <span class="text-uppercase d-flex align-items-center">
                    <ng-container
                      [ngTemplateOutlet]="pulse"
                      [ngTemplateOutletContext]="{
                        isOpened: schedule.isOpened
                      }"></ng-container>
                    {{
                      (schedule.isOpened
                        ? 'rrs.storeFinder.openToday'
                        : 'rrs.storeFinder.closedToday'
                      ) | cxTranslate
                    }}:
                  </span>
                </ng-template>
                <span>
                  {{
                    schedule.isOpened
                      ? schedule.formattedOpeningHours
                      : ('rrs.storeFinder.closed' | cxTranslate)
                  }}
                  <span *ngIf="schedule.isSpecialHours" class="text-accent"
                    >*</span
                  >
                </span>
              </div>
              <div class="d-flex justify-content-end mt-2">
                <span class="text-accent me-1">*</span
                >{{ 'rrs.storeFinder.specialStoreHours' | cxTranslate }}
              </div>
            </div>
          </div>
          <div
            *ngIf="nearbyStores$ | async as nearbyStores"
            class="d-none d-lg-block mt-4">
            <h3 class="fw-normal">
              {{ 'rrs.storeDetails.nearbyLocations' | cxTranslate }}
            </h3>
            <ng-template [ngTemplateOutlet]="nearbyStoresList"></ng-template>
          </div>
        </div>

        <div class="col-lg-6 order-lg-0 mt-3 mt-lg-0">
          <cx-page-slot position="news_store_events_content"></cx-page-slot>
        </div>

        <rrs-accordion class="accordion--nearby-stores d-lg-none mt-3">
          <accordion-item>
            <ng-template accordionHeader let-toggle="toggle">
              <div
                (click)="toggle()"
                (keydown.enter)="toggle()"
                tabindex="0"
                class="d-flex align-items-center justify-content-between py-2">
                <h3 class="fw-normal mb-0">
                  {{ 'rrs.storeDetails.nearbyLocations' | cxTranslate }}
                </h3>
                <rrs-icon
                  class="accordion-icon"
                  [icon]="iconTypeList.CHEVRON_DOWN"
                  [size]="16"></rrs-icon>
              </div>
            </ng-template>
            <ng-template accordionContent>
              <ng-template [ngTemplateOutlet]="nearbyStoresList"></ng-template>
            </ng-template>
          </accordion-item>
        </rrs-accordion>
      </div>
    </div>

    <ng-template #nearbyStoresList let-nearbyStores="nearbyStores">
      <div
        *ngFor="let location of nearbyStores$ | async"
        class="store__nearby-location d-flex align-items-center py-4">
        <div class="me-2">
          <rrs-store-logo [store]="location" [size]="80"></rrs-store-logo>
        </div>
        <div class="flex-grow-1">
          <h5>{{ location.displayName }}</h5>
          <p class="mb-0">
            {{ location.address?.line1 }} {{ location.address?.town }},
            {{ location.address?.region?.isocodeShort }}
            {{ location.address?.postalCode }} <br />
            {{ location.phoneNumber | rrsPhoneNumber }}
          </p>
          <a
            class="link-primary"
            [routerLink]="
              {
                cxRoute: 'storeDetails',
                params: { storeId: location.name }
              } | cxUrl
            ">
            {{ 'rrs.storeDetails.seeStoreDetails' | cxTranslate }}</a
          >
        </div>
      </div>
    </ng-template>

    <ng-template #pulse let-isOpened="isOpened">
      <div
        class="pulse me-1"
        [ngClass]="isOpened ? 'pulse--green' : 'pulse--red'">
        <span>
          <span>
            <span></span>
          </span>
        </span>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
<ng-template #pageLoader>
  <div class="d-flex align-items-center justify-content-center p-5">
    <div class="spinner-border" role="status" style="width: 6rem; height: 6rem">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
