<div *ngIf="service.toastObj$ | async as toastObj">
  <div
    [@toastTrigger]="(service.showsToast$ | async) ? 'open' : 'close'"
    class="rrs-toast position-fixed"
    [class]="toastObj.toastPostion">
    <div class="toast-content">
      <div class="toast-header">
        <img
          *ngIf="toastObj.headerIconFileName"
          [src]="'assets/icons/' + toastObj.headerIconFileName"
          alt="Icon" />
        <span *ngIf="toastObj.toastHeaderText">{{
          toastObj.toastHeaderText
        }}</span>
      </div>
      <div class="toast-body" *ngIf="toastObj.toastBody">
        {{ toastObj.toastBody }}
      </div>
    </div>
    <button
      class="btn btn-sm btn-primary"
      *ngIf="toastObj.toastButtonText"
      (click)="onClick()">
      {{ toastObj.toastButtonText }}
    </button>
  </div>
</div>
