import {
  OutletModule,
  OutletRefModule,
  PageLayoutModule,
  PageSlotModule,
  SkipLinkModule,
} from '@spartacus/storefront';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RrsGlobalMessageModule } from '@app/custom/features/rrs-global-message';
import { RrsScrollToTopModule } from '@app/shared/components';
import { RrsStorefrontComponent } from '@app/custom/features/rrs-storefront/rrs-storefront.component';
import { RrsEndOfContentComponent } from '@app/custom/features/rrs-storefront/components/rrs-end-of-content.component';
import { RrsSharedPipesModule } from '@app/shared/pipes';
import { RrsToastComponent } from '@app/custom/features/rrs-toast/components/rrs-toast.component';

@NgModule({
  declarations: [
    RrsStorefrontComponent,
    RrsEndOfContentComponent,
    RrsToastComponent,
  ],
  exports: [RrsStorefrontComponent],
  imports: [
    CommonModule,
    OutletModule,
    OutletRefModule,
    PageLayoutModule,
    PageSlotModule,
    RouterModule,
    RrsGlobalMessageModule,
    RrsScrollToTopModule,
    SkipLinkModule,
    RrsSharedPipesModule,
  ],
})
export class RrsStorefrontModule {}
