import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModalRef, ModalService } from '@app/custom/features/modal';
import { DONATION_PRODUCT_TYPE } from '@app/custom/features/rrs-donation/models/donation.constant';
import { RrsProductDetailsZoomComponent } from '@app/custom/features/rrs-product-details/components/rrs-product-details-zoom/rrs-product-details-zoom.component';
import { RrsShopLookService } from '@app/custom/features/rrs-shop-look/services/rrs-shop-look.service';
import { RrsAddToCartSuccessEvent } from '@app/custom/features/rrs-tms/rrs-adobe-experience/events/model/events';
import { Badge } from '@app/shared/configs';
import { Scene7Presets } from '@app/shared/configs/scene7.config';
import { AutoUnsubscribe } from '@app/shared/decorators';
import { getBadges, includeBogoBadgesIfApplicable } from '@app/shared/utils';
import { ICON_TYPE_LIST } from '@app/spartacus/configurations/icon/icon.model';
import { RrsProductScope } from '@app/spartacus/configurations/types.config';
import { EventService, Product, ProductService } from '@spartacus/core';
import { BREAKPOINT, BreakpointService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import SwiperCore, { Pagination, SwiperOptions, Zoom } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
SwiperCore.use([Pagination, Zoom]);
@AutoUnsubscribe()
@Component({
  selector: 'rrs-shop-look-mini',
  templateUrl: './rrs-shop-look-mini.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RrsShopLookMiniComponent implements OnInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  productCode: string = '';
  productCodes!: string[];
  product$!: Observable<Product | undefined>;
  productGallery$: any;
  brandCategory$: any;
  configMain: SwiperOptions = {
    navigation: true,
    pagination: {
      enabled: true,
      clickable: true,
    },
    slidesPerGroup: 1,
    slidesPerView: 1,
    threshold: 10,
    zoom: {
      maxRatio: 3,
      minRatio: 1,
      zoomedSlideClass: 'swiper-zoom-container',
    },
  };
  badges: Array<Badge> = [];
  bogoBadges: Array<Badge> = [];
  donationProductType = DONATION_PRODUCT_TYPE;
  isDonation: boolean = false;
  isLoading$!: Observable<boolean>;
  scene7Preset = Scene7Presets.ZOOM;
  iconTypeList = ICON_TYPE_LIST;
  model$: any;
  subscription: Subscription = new Subscription();
  title = this.rrsShopLookService.title;
  isMobile: boolean = false;
  modalRef: ModalRef | undefined;
  activeSlide: number = 0;
  constructor(
    protected productService: ProductService,
    protected rrsShopLookService: RrsShopLookService,
    protected modalService: ModalService,
    protected eventService: EventService,
    protected breakPointService: BreakpointService
  ) {
    this.subscription.add(
      this.eventService.get(RrsAddToCartSuccessEvent).subscribe((_res) => {
        this.modalService.dismissActiveModal();
      })
    );
    this.subscription.add(
      this.breakPointService.isDown(BREAKPOINT.sm).subscribe((isMobile) => {
        this.isMobile = isMobile;
      })
    );
  }
  zoomIn(): void {
    if (this.isMobile) {
      return;
    }
    this.swiper?.swiperRef.zoom.in();
  }
  zoomOut(): void {
    this.swiper?.swiperRef.zoom.out();
  }
  onSlideChange(): void {
    this.activeSlide = this.swiper?.swiperRef?.activeIndex || 0;
  }
  openModal(): void {
    if (this.isMobile) {
      this.modalRef = this.modalService.open(RrsProductDetailsZoomComponent, {
        size: 'fullscreen',
        windowClass: 'product-details-zoom-modal',
        centered: !this.isMobile,
      });

      const modalInstance = this.modalRef.componentInstance;
      modalInstance.activeSlide = this.activeSlide;
      modalInstance.product$ = this.product$;
      modalInstance.productGallery$ = this.productGallery$;
    }
  }

  ngOnInit(): void {
    this.rrsShopLookService.shopLookOpen = true;
    if (this.productCode) {
      this.rrsShopLookService.code = this.productCode;
      this.product$ = this.productService
        .get(this.productCode, RrsProductScope.ALL)
        .pipe(
          map((product) => {
            this.isDonation =
              product?.baseProduct === this.donationProductType ||
              product?.code === this.donationProductType;

            this.badges = getBadges(product?.categories || []);
            this.bogoBadges = includeBogoBadgesIfApplicable(
              product?.categories || []
            );

            return product;
          })
        );
      this.isLoading$ = this.productService.isLoading(
        this.productCode,
        RrsProductScope.ALL
      );
      this.productGallery$ = this.product$.pipe(
        map((product) => this.createGallery(product || {})),
        // swiper needs to update after DOM changes
        tap(() => {
          setTimeout(() => {
            this.swiper?.swiperRef?.update();
          }, 0);
        })
      );
      this.brandCategory$ = this.product$.pipe(
        map((product) =>
          product?.categories?.find((category) =>
            category.code?.includes('brand')
          )
        )
      );
    } else {
      if (this.productCodes.length) {
        this.rrsShopLookService.codes = this.productCodes;
        this.model$ = this.rrsShopLookService
          .getProducts({
            facetFilters:
              '[' +
              this.rrsShopLookService.getSkuStringforSearch(this.productCodes) +
              ']',
          })
          .pipe(filter((searchResult) => Object.keys(searchResult).length > 0));
      }
    }
  }

  protected createGallery(product: Product): any[] {
    if (!product?.images?.GALLERY) return [];

    return (product.images.GALLERY as any[]).map((c) => {
      const container = {
        zoom: {
          ...c.zoom,
          url: `${c.zoom.url}&${Scene7Presets.ZOOM}`,
        },
      };
      return { container };
    });
  }

  openPdp(code: string): void {
    this.rrsShopLookService.openPDP(code);
  }
  openPLP(): void {
    this.rrsShopLookService.openPLP();
  }
}
