import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  KLARNA_CLIENT_ID,
  KLARNA_SCRIPT_SRC,
} from '@app/custom/features/rrs-klarna-banner/rrs-klarna-banner.config';
import { WindowRef } from '@spartacus/core';

declare global {
  interface Window {
    Klarna: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class RrsKlarnaBannerService {
  nativeWindow = this.winRef.nativeWindow as Window;
  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected winRef: WindowRef
  ) {}

  initKlarnaBanner(): void {
    const klarnaBannerScript = this.document.createElement('script');
    klarnaBannerScript.async = true;
    klarnaBannerScript.src = KLARNA_SCRIPT_SRC;
    klarnaBannerScript.setAttribute('data-client-id', KLARNA_CLIENT_ID);
    this.document.head.appendChild(klarnaBannerScript);
  }
}
